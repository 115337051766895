import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/jobtitle`

export default class JobTitleService extends BaseService {
  async getAllJobTitles () {
    return this.sendRequest('GET', `${serviceUrl}/all`)
  }
  async createJobTitle (jobtitle) {
    console.log('data', jobtitle)
    return this.sendRequest('POST', `${serviceUrl}/new`, jobtitle)
  }
  async getPendingJobTitles () {
    return this.sendRequest('GET', `${serviceUrl}/pending`)
  }
  async getJobTitle (id) {
    return this.sendRequest('GET', `${serviceUrl}/${id}`)
  }
  async getJobTitleByUnion (id) {
    return this.sendRequest('GET', `${serviceUrl}/get-by-union/${id}`)
  }
  async approveJobtitle (jobtitle) {
    return this.sendRequest('POST', `${serviceUrl}/audit/approve`, jobtitle)
  }
  async rejectJobtitle (jobtitle) {
    return this.sendRequest('POST', `${serviceUrl}/audit/reject`, jobtitle)
  }
  async updateJobtitle (jobtitle) {
    return this.sendRequest('POST', `${serviceUrl}/update`, jobtitle)
  }
  async deleteJobtitle (jobtitle) {
    return this.sendRequest('POST', `${serviceUrl}/delete`, jobtitle)
  }
}
