<template>
  <button @click="scrollToTop" id="scroll-button" class="b" title="Scroll to top"><span class="icon-chevron-up"></span></button>
</template>

<script>

// window.onscroll = function () { scroll() }

// function scroll() {
//  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
//    document.getElementById('scroll-button').style.display = 'block'
//  } else {
//    document.getElementById('scroll-button').style.display = 'none'
//  }
// }

export default {
  /* global $ */
  name: 'ScrollToTopButton',
  methods: {
    scrollToTop () {
      // document.body.scrollTop = 0; // For Safari
      // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      $('html,body').animate({ scrollTop: 0 }, 500)
    }
  },
  mounted () {
    window.onscroll = () => {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById('scroll-button').style.display = 'block'
      } else {
        document.getElementById('scroll-button').style.display = 'none'
      }
    }
  },
  beforeDestroy () {
    window.onscroll = null
  }
}

</script>
<style scoped lang="scss">

  #scroll-button {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: #0066b2; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 10px; /* Some padding */
    border-radius: 50%; /* Rounded corners */
    font-size: 18px; /* Increase font size */
    width: 50px;
    height: 50px;
  }

    #scroll-button:hover {
      background-color: #0b2d71; /* Add a dark-grey background on hover */
    }
</style>
