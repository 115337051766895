import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/salaryperiods`
const mserviceUrl = `/api/${Constants.BASE_API_VERSION}/SalaryHistory/salary`

export default class SalaryPeriodService extends BaseService {
  async getAllSalaryPeriod () {
    return this.sendRequest('GET', `${serviceUrl}/all`)
  }
  async getAllSalaryPeriodByYear (val) {
    return this.sendRequest('GET', `${serviceUrl}/year/${val}`)
  }
  async getOpenSalaryPeriod () {
    return this.sendRequest('GET', `${serviceUrl}/open`)
  }

  async closeSalaryPeriod (id) {
    return this.sendRequest('GET', `${serviceUrl}/close/${id}`)
  }
  async processSalaryPeriod (id) {
    return this.sendRequest('GET', `${serviceUrl}/process/${id}`)
  }
  async getAllSalaryHistroy () {
    return this.sendRequest('GET', `${mserviceUrl}/all/${0}`)
  }
  async getSalaryYears () {
    return this.sendRequest('GET', `${serviceUrl}/years`)
  }
}
