import Vue from 'vue'
import VeeValidate from 'vee-validate'
// import msal from 'vue-msal'
import vuetify from '@/plugins/vuetify'
import excel from 'vue-excel-export'
import LogRocket from 'logrocket'
// import AppSecond from './AppSecond.vue'
import App from './App.vue'
import CompleteContractEmployerKyc from './views/contract-employer/CompleteContractEmployerKyc.vue'
import Login from './Login.vue'
import Error from './Error.vue'
import LandingPage from './Landing.vue'
import InvalidPage from './InvalidUser.vue'
import router from './router'
import store from './store'
import './assets/myStyle.css'
import moment from 'moment'
import vueCurrencyInput from 'vue-currency-input'

import { ActionTypes } from '@/utils/constants'
//import AdalAuthentication from '@/adal-auth'
import MsalAuthentication from '@/msal-auth'
import AccountService from '@/services/accountService'
import VueNumericInput from 'vue-numeric-input'

Vue.use(vueCurrencyInput)
Vue.use(VeeValidate)
Vue.use(VueNumericInput)
Vue.use(excel)
Vue.config.productionTip = false

Vue.filter('formatDate', function (value) {
  if (value === null || value === undefined) {
    return ''
  }
  return moment(value).format('MM-DD-YYYY')
})

Vue.filter('formatMoney', function (amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    let j = (i.length > 3) ? i.length % 3 : 0

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
  } catch (e) {
    console.log(e)
  }
})

const showApp = () => {
  new Vue({
    router,
    store,
    vuetify,
    data: {
      eventBus: new Vue()
    },
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    render: h => h(App)
  }).$mount('#app')
}

const showLandingPage = () => {
  new Vue({
    router,
    store,
    vuetify,
    data: {
      eventBus: new Vue()
    },
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    render: h => h(LandingPage)
  }).$mount('#app')
}

const showError = error => {
  new Vue({
    vuetify,
    store,
    router,
    data: {
      error
    },
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    render: h => h(Error) // replace with error view
  }).$mount('#app')
}

const showNoAccess = error => {
  new Vue({
    vuetify,
    store,
    router,
    data: {
      error
    },
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    render: h => h(InvalidPage) // replace with error view
  }).$mount('#app')
}

const showLogin = () => {
  new Vue({
    vuetify,
    store,
    router,
    data: {
      eventBus: new Vue()
    },
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    render: h => h(Login)
  }).$mount('#app')
}

const showContractorEmployer = () => {
  new Vue({
    vuetify,
    store,
    router,
    data: {
      eventBus: new Vue()
    },
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    render: h => h(CompleteContractEmployerKyc)
  }).$mount('#app')
}

showLandingPage()

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('ywnzvp/cmms-q7zxm')
}



MsalAuthentication.initialize()
  .then(() => MsalAuthentication.isAuthenticated())
  .then(result => {
    if (result === true && localStorage.getItem('user-token') == null) {
      MsalAuthentication.acquireToken()
        .then(msGraphToken => new AccountService().login(msGraphToken))
        .then((data) => {
          try {
            store.dispatch(ActionTypes.GET_CURRENT_USER).then(result => {
              if (result.status === 200) {
                showApp()
              } else if (result.status === 404) {
                showNoAccess()
              } else {
                showError()
              }
            })
          } catch (error) {
            showLogin()
          }
        })
        .catch(() => {
          showLogin()
        })
    }
    else if (localStorage.getItem('user-token') !== null) {
      store.dispatch(ActionTypes.GET_CURRENT_USER).then(result => {
        const username = localStorage.getItem('newuser')
        if (username !== null && username !== undefined) {
          showContractorEmployer()
        } else {
          showApp()
        }
      })
    }
    else {
      showLogin()
    }

  })
  .catch(() => {
    showError()
  })

