import EmployeeService from './employeeService'
import UserService from './userService'
import AccountService from './accountService'
import RoleService from './roleService'
import LocationService from './locationService'
import DepartmentService from './departmentServices'
import WorkAreaService from './workAreaService'
import ChargeCodeService from './chargeCodeService'
import JobTitleService from './jobTitleService'
import ContractEmployerService from './contractEmployer'
import NmaIntegrationService from './nmaIntegrationservices'
import SalaryComponentService from './salaryService'
import BenefitSetupService from './benefitsSetupService'
import EmployeeAuditTrailService from './employeeAuditTrailServices'
import RotationService from './rotationServices'
import SalaryPeroidService from './salaryPeriodService'
import RequestService from './requestService'
import StateService from './stateServices'
import TimesheetService from './timesheetService'
import Benefits from './benefits'
import MedicalService from './medicalService'
import EmployeeRequisition from './employeeRequisition'
import ContractEmployeeruser from './contractEmployeeruser'
import HolidayService from './holidayService'
import PaymentScheduleService from './paymentScheduleService'
import MedicalScreeningService from './medicalScreeningService'
import MedicalScreeningCategoryService from './medicalScreeningCategoryService'
import MedicalScreeningSubCategoryService from './medicalScreeningSubCategoryService'
import UnionMemberService from './unionMemberService'
import EmployeeDeductionService from './employeeDeductionService'
import ReportService from './reportService'
import MessageServices from './messaging'
import EmployeesMoveAuditTrailsService from './employeeMoveAuditTrailService'

export const employeeService = new EmployeeService()
export const userService = new UserService()
export const accountService = new AccountService()
export const roleService = new RoleService()
export const locationService = new LocationService()
export const departmentService = new DepartmentService()
export const workAreaService = new WorkAreaService()
export const chargeCodeService = new ChargeCodeService()
export const jobTitleService = new JobTitleService()
export const contractEmployerService = new ContractEmployerService()
export const salaryComponentService = new SalaryComponentService()
export const benefitSetupService = new BenefitSetupService()
export const employeeAuditTrailService = new EmployeeAuditTrailService()
export const nmaIntegrationService = new NmaIntegrationService()
export const rotationService = new RotationService()
export const salaryPeriodService = new SalaryPeroidService()
export const requestService = new RequestService()
export const stateService = new StateService()
export const timesheetService = new TimesheetService()
export const benefitService = new Benefits()
export const medicalService = new MedicalService()
export const employeeRequisition = new EmployeeRequisition()
export const contractEmployeeruser = new ContractEmployeeruser()
export const holidayService = new HolidayService()
export const paymentScheduleService = new PaymentScheduleService()
export const medicalScreeningService = new MedicalScreeningService()
export const medicalScreeningCategoryService = new MedicalScreeningCategoryService()
export const medicalScreeningSubCategoryService = new MedicalScreeningSubCategoryService()
export const unionMemberService = new UnionMemberService()
export const employeeDeductionService = new EmployeeDeductionService()
export const reportService = new ReportService()
export const messagingService = new MessageServices()
export const employeeMoveAuditTrailService = new EmployeesMoveAuditTrailsService()
