import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/state`

export default class StateService extends BaseService {
  async getAllStates () {
    return this.sendRequest('GET', `${serviceURL}`)
  }
  async createState (state) {
    return this.sendRequest('POST', `${serviceURL}`, state)
  }
  async getPendingState () {
    return this.sendRequest('GET', `${serviceURL}/pending/all`)
  }
  async stateStatus (state) {
    return this.sendRequest('POST', `${serviceURL}/status`, state)
  }
  async updateState (id, state) {
    return this.sendRequest('PATCH', `${serviceURL}/audit/${id}`, state)
  }
}
