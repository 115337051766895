import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/timesheet`

export default class TimesheetService extends BaseService {
  async getTimesheet () {
    return this.sendRequest('GET', `${serviceUrl}/`)
  }

  async createTimesheet (request) {
    return this.sendRequest('POST', `${serviceUrl}`, request)
  }

  async saveTimesheet (request) {
    return this.sendRequest('POST', `${serviceUrl}/update-timesheet`, request)
  }

  async filterTimesheet (salaryCycleId, rotationId) {
    return this.sendRequest('GET', `${serviceUrl}/filter/${salaryCycleId}/${rotationId}`)
  }

  async pendingTimesheet () {
    return this.sendRequest('GET', `${serviceUrl}/pending`)
  }

  async downloadTimesheet (request) {
    return this.sendRequest('POST', `${serviceUrl}/download`, request, {}, 'blob')
  }

  async uploadTimesheet (payload) {
    return this.sendRequest('POST', `${serviceUrl}/upload-timesheet`, payload)
  }

  async getUnApprovedEmployeeTimesheet (employeeId, salaryCycleId) {
    return this.sendRequest('GET', `${serviceUrl}/unapproved/${employeeId}/${salaryCycleId}`)
  }

  async getEmployeeTimesheet (salaryCycleId, status) {
    return this.sendRequest('GET', `${serviceUrl}/view/${salaryCycleId}/${status}`)
  }
  async getTimesheetReport (data) {
    return this.sendRequest('POST', `${serviceUrl}/view/report`, data)
  }
  async approveTimesheet (payload) {
    return this.sendRequest('POST', `${serviceUrl}/approval/bulk`, payload)
  }

  async downloadUserGuide (id) {
    return this.sendRequest('POST', `${serviceUrl}/download-guide/${id}`, {}, 'blob')
  }

  async forwardTimesheet (payload) {
    return this.sendRequest('POST', `${serviceUrl}/forward`, payload)
  }

  async viewTimesheetApprovalDetail (id) {
    return this.sendRequest('GET', `${serviceUrl}/approval/details/${id}`)
  }

  async viewTimesheetDetail (id) {
    return this.sendRequest('GET', `${serviceUrl}/${id}`)
  }

  async viewTimesheetLogs (id) {
    return this.sendRequest('GET', `${serviceUrl}/logs/${id}`)
  }
}
