import { jobTitleService, unionMemberService } from '../../../services'

import {
  CREATE_JOBTITLE,
  FETCH_JOBTITLE,
  APPROVE_JOBTITLE,
  FETCH_PENDING_JOBTITLE,
  UPDATE_JOBTITLE,
  DELETE_JOBTITLE,
  REJECT_JOBTITLE,
  FETCH_PENDING_UNIONJOBTITLESALARIES,
  APPROVE_UNIONJOBTITLESALARIES
} from '../../action-type'
import {

  SET_JOBTITLE,
  SET_JOBTITLE__APPROVAL,
  SET_PENDING_JOBTITLE,
  EDIT_JOBTITLE,
  REMOVE_JOBTITLE,
  SET_PENDING_UNIONJOBTITLE,
  SET_UNIONJOBTITLE_APPROVAL
} from '../../mutation-type'

export default {
  state: {
    jobTitles: [],
    pendingJobTitles: [],
    pendingUnionJobtitleSalaries: []
  },
  mutations: {

    [SET_JOBTITLE] (state, payload) {
      state.jobTitles = [...payload]
    },
    [SET_PENDING_JOBTITLE] (state, payload) {
      state.pendingJobTitles = [...payload]
    },
    [SET_JOBTITLE__APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingJobTitles.length; i++) {
        if (state.pendingJobTitles[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingJobTitles.splice(index, 1)
    },
    [SET_UNIONJOBTITLE_APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingUnionJobtitleSalaries.length; i++) {
        if (state.pendingUnionJobtitleSalaries[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingUnionJobtitleSalaries.splice(index, 1)
    },
    [EDIT_JOBTITLE] (state, payload) {

    },
    [REMOVE_JOBTITLE] (state, payload) {
      let index = null
      for (let i = 0; i < state.jobTitles.length; i++) {
        if (state.jobTitles[i].id === payload.id) {
          index = i
          break
        }
      }
      state.jobTitles.splice(index, 1)
    },
    [SET_PENDING_UNIONJOBTITLE] (state, payload) {
      state.pendingUnionJobtitleSalaries = payload
    }

  },
  actions: {
    [CREATE_JOBTITLE] ({ commit }, jobTitle) {
      return new Promise((resolve, reject) => {
        jobTitleService
          .createJobTitle(jobTitle)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [FETCH_JOBTITLE] ({ commit }) {
      return new Promise((resolve, reject) => {
        jobTitleService
          .getAllJobTitles()
          .then(result => {
            localStorage.setItem('jobTitle', JSON.stringify(result.data.items))
            commit(SET_JOBTITLE, result.data.items)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    [FETCH_PENDING_UNIONJOBTITLESALARIES] ({ commit }) {
      return new Promise((resolve, reject) => {
        unionMemberService.getPendingUnionAllowance().then(result => {
          commit(SET_PENDING_UNIONJOBTITLE, result.data)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    [APPROVE_JOBTITLE] ({ commit }, jobTitle) {
      return new Promise((resolve, reject) => {
        jobTitleService
          .approveJobtitle(jobTitle)
          .then(result => {
            commit(SET_JOBTITLE__APPROVAL, jobTitle)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    [APPROVE_UNIONJOBTITLESALARIES] ({ commit }, unionJobtitleSalary) {
      return new Promise((resolve, reject) => {
        unionMemberService.approveUnionAllowance(unionJobtitleSalary).then(() => {
          commit(SET_UNIONJOBTITLE_APPROVAL, unionJobtitleSalary)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    [REJECT_JOBTITLE] ({ commit }, jobTitle) {
      return new Promise((resolve, reject) => {
        jobTitleService
          .rejectJobtitle(jobTitle)
          .then(result => {
            commit(SET_JOBTITLE__APPROVAL, jobTitle)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    [FETCH_PENDING_JOBTITLE] ({ commit }) {
      return new Promise((resolve, reject) => {
        jobTitleService.getPendingJobTitles().then(result => {
          commit(SET_PENDING_JOBTITLE, result.data.items)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    [UPDATE_JOBTITLE] ({ commit }, jobTitle) {
      return new Promise((resolve, reject) => {
        jobTitleService.updateJobtitle(jobTitle).then(result => {
          commit(EDIT_JOBTITLE, jobTitle)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    [DELETE_JOBTITLE] ({ commit }, jobTitle) {
      return new Promise((resolve, reject) => {
        jobTitleService.deleteJobtitle(jobTitle).then(result => {
          commit(REMOVE_JOBTITLE, jobTitle)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }

  },
  getters: {
    jobTitles (state) {
      return state.jobTitles
    },
    pendingJobTitles (state) {
      return state.pendingJobTitles
    },
    pendingUnionJobtitleSalaries (state) {
      return state.pendingUnionJobtitleSalaries
    }
  }
}
