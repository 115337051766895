import { requestService } from '../../../services'

import {
  CREATE_REQUEST,
  FETCH_PENDING_REQUEST,
  FETCH_PENDING_PPES,
  FETCH_PENDING_TRAINING,
  APPROVE_REQUEST,
  APPROVE_BULK_REQUEST,
  FETCH_PENDING_ALLOWANCES,
  FETCH_PENDING_LEAVE,
  FETCH_ALLOWANCE,
  FETCH_LEAVE,
  FETCH_PPE,
  FETCH_TRAINING,
  APPROVE_BULK_LEAVE,
  APPROVE_BULK_PPE,
  APPROVE_BULK_TRAINING,
  FORWARD_REQUEST
} from '../../action-type'

import {
  ADD_REQUEST,
  SET_PENDING_REQUEST,
  SET_PENDING_TRAINING,
  SET_REQUEST_APPROVAL,
  SET_BULK_REQUEST_APPROVAL,
  SET_PENDING_PPES,
  SET_PENDING_ALLOWANCES,
  SET_PENDING_LEAVE,
  SET_ALLOWANCES,
  SET_LEAVE,
  SET_PPE,
  SET_TRAINING,
  SET_BULK_LEAVE_APPROVAL,
  SET_BULK_PPE_APPROVAL,
  SET_BULK_TRAINING_APPROVAL,
  REMOVE_FORWARDED_REQUEST
} from '../../mutation-type'

export default {
  state: {
    requests: [],
    pendingRequests: [],
    pendingPpes: [],
    pendingTraining: [],
    pendingAllowances: [],
    pendingLeave: [],
    allowances: [],
    leaves: [],
    ppes: [],
    trainings: []
  },
  mutations: {
    [ADD_REQUEST] (state, payload) {
      state.requests = [...payload]
    },
    [SET_PENDING_REQUEST] (state, payload) {
      state.pendingRequests = [...payload]
    },
    [SET_PENDING_PPES] (state, payload) {
      state.pendingPpes = payload
    },
    [SET_PENDING_TRAINING] (state, payload) {
      state.pendingTraining = payload
    },
    [SET_PENDING_ALLOWANCES] (state, payload) {
      state.pendingAllowances = payload
    },
    [SET_PENDING_LEAVE] (state, payload) {
      state.pendingLeave = payload
    },
    [SET_REQUEST_APPROVAL] (state, payload) {
      const approveType = (requestType) => {
        let index = null
        for (let i = 0; i < requestType.length; i++) {
          if (requestType[i].id === payload.id) {
            index = i
            break
          }
        }
        requestType.splice(index, 1)
      }

      if (payload.type === 'Training') {
        approveType(state.pendingTraining)
      }
      if (payload.type === 'PPE') {
        approveType(state.pendingPpes)
      } if (payload.type === 'Allowance') {
        console.log(payload.type)
        approveType(state.pendingAllowances)
      }
      if (payload.type === 'Leave') {
        approveType(state.pendingLeave)
      }
    },
    [SET_BULK_REQUEST_APPROVAL] (state, payload) {
      console.log(payload)

      if (payload[0].type === 'Training') {
        const newArray = state.pendingTraining.filter(i => !payload.some(j => j.id === i.id))

        state.pendingTraining = newArray
      }
      if (payload[0].type === 'PPE') {
        const newArray = state.pendingPpes.filter(i => !payload.some(j => j.id === i.id))

        state.pendingPpes = newArray
      }
      if (payload[0].type === 'Allowance') {
        const newArray = state.pendingAllowances.filter(i => !payload.some(j => j.id === i.id))

        state.pendingAllowances = newArray
      }
    },
    [SET_BULK_LEAVE_APPROVAL] (state, payload) {
      const newArray = state.pendingLeave.filter(i => !payload.some(j => j.GUID === i.guid))

      state.pendingLeave = newArray
    },
    [SET_BULK_PPE_APPROVAL] (state, payload) {
      const newArray = state.pendingPpes.filter(i => !payload.some(j => j.id === i.requestId))

      state.pendingPpes = newArray
    },
    [SET_BULK_TRAINING_APPROVAL] (state, payload) {
      const newArray = state.pendingTraining.filter(i => !payload.some(j => j.id === i.requestId))

      state.pendingTraining = newArray
    },
    [SET_ALLOWANCES] (state, payload) {
      state.allowances = payload
    },
    [SET_LEAVE] (state, payload) {
      state.leaves = payload
    },
    [SET_PPE] (state, payload) {
      state.ppes = payload
    },
    [SET_TRAINING] (state, payload) {
      state.trainings = payload
    },
    [REMOVE_FORWARDED_REQUEST] (state, { data, type }) {
      // console.log('i got here ' + type + ' payload ' + data)
      // if (type === 'Training') {
      //   state.pendingTraining = state.pendingTraining.filter(req => req.requestId !== data.itemId)
      // }
      // if (type === 'PPE') {
      //   state.pendingPpes = state.pendingPpes.filter(req => req.requestGuid !== data.ItemGuid)
      // }
      // if (type === 'Leave') {
      //   state.pendingLeave = state.pendingLeave.filter(req => req.guid !== data.ItemGuid)
      // }
    }
  },
  actions: {
    [CREATE_REQUEST] ({ commit }, request) {
      return new Promise((resolve, reject) => {
        if (request.type === 'training') {
          requestService.createTrainingRequest(request.data).then(result => {
            commit(ADD_REQUEST, request.data)
            resolve()
          }).catch(error => {
            reject(error)
          })
        } else if (request.type === 'PPE') {
          requestService.createPPERequest(request.data).then(result => {
            commit(ADD_REQUEST, request.data)
            resolve()
          }).catch(error => {
            reject(error)
          })
        } else if (request.type === 'leave') {
          requestService.createLeaveRequest(request.data).then(result => {
            commit(ADD_REQUEST, request.data)
            resolve()
          }).catch(error => {
            reject(error)
          })
        } else {
          requestService.createAllowanceRequest(request.data).then(result => {
            commit(ADD_REQUEST, request.data)
            resolve()
          }).catch(error => {
            reject(error)
          })
        }
      })
    },
    async [FETCH_PENDING_REQUEST] ({ commit }) {
      return new Promise((resolve, reject) => {
        requestService.getPendingRequest().then(result => {
          console.log(result.data)
          commit(SET_PENDING_REQUEST, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    async [FETCH_PENDING_PPES] ({ commit }) {
      console.log('i got to this point')
      return new Promise((resolve, reject) => {
        requestService.getPendingPpeTypes().then(result => {
          commit(SET_PENDING_PPES, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_PENDING_LEAVE] ({ commit }) {
      return new Promise((resolve, reject) => {
        requestService.getPendingLeave().then(result => {
          commit(SET_PENDING_LEAVE, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    async [FETCH_PENDING_TRAINING] ({ commit }) {
      return new Promise((resolve, reject) => {
        requestService.getPendingTraining().then(result => {
          console.log(result)
          commit(SET_PENDING_TRAINING, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_PENDING_ALLOWANCES] ({ commit }) {
      return new Promise((resolve, reject) => {
        requestService.getPendingAllowances().then(result => {
          commit(SET_PENDING_ALLOWANCES, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_REQUEST] ({ commit }, request) {
      return new Promise((resolve, reject) => {
        requestService.approveSingleRequest(request).then(() => {
          commit(SET_REQUEST_APPROVAL, request)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_BULK_REQUEST] ({ commit }, requests) {
      return new Promise((resolve, reject) => {
        requestService.approveBulkRequest(requests).then(() => {
          commit(SET_BULK_REQUEST_APPROVAL, requests)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_BULK_LEAVE] ({ commit }, requests) {
      return new Promise((resolve, reject) => {
        requestService.approveBulkLeave(requests).then(() => {
          commit(SET_BULK_LEAVE_APPROVAL, requests)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_BULK_PPE] ({ commit }, requests) {
      return new Promise((resolve, reject) => {
        requestService.approveBulkPpe(requests).then(() => {
          // commit(SET_BULK_PPE_APPROVAL, requests)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_BULK_TRAINING] ({ commit }, requests) {
      return new Promise((resolve, reject) => {
        requestService.approveBulkTraining(requests).then(() => {
          commit(SET_BULK_TRAINING_APPROVAL, requests)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_ALLOWANCE] ({ commit }) {
      return new Promise((resolve, reject) => {
        requestService.getAllowanceAll().then(result => {
          commit(SET_ALLOWANCES, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_LEAVE] ({ commit }) {
      return new Promise((resolve, reject) => {
        requestService.getLeaveAll().then(result => {
          commit(SET_LEAVE, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_PPE] ({ commit }) {
      return new Promise((resolve, reject) => {
        requestService.getPPEAll().then(result => {
          commit(SET_PPE, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_TRAINING] ({ commit }) {
      return new Promise((resolve, reject) => {
        requestService.getTrainingAll().then(result => {
          commit(SET_TRAINING, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FORWARD_REQUEST] ({ commit }, { data, type }) {
      return new Promise((resolve, reject) => {
        if (type === 'Training') {
          return requestService.forwardTraining(data).then(result => {
            commit(REMOVE_FORWARDED_REQUEST, { data, type })
            resolve()
          }).catch(error => {
            reject(error)
          })
        }
        if (type === 'PPE') {
          return requestService.forwardPPE(data).then(result => {
            commit(REMOVE_FORWARDED_REQUEST, { data, type })
            resolve()
          }).catch(error => {
            reject(error)
          })
        }
        if (type === 'Leave') {
          return requestService.forwardLeave(data).then(result => {
            commit(REMOVE_FORWARDED_REQUEST, { data, type })
            resolve()
          }).catch(error => {
            reject(error)
          })
        }
      })
    }
  },
  getters: {
    pendingRequests (state) {
      return state.pendingRequests
    },
    pendingPpeType (state) {
      return state.pendingPpes
    },
    pendingTraining (state) {
      return state.pendingTraining
    },
    pendingAllowances: state => state.pendingAllowances,
    pendingLeave: state => state.pendingLeave,
    allowances: state => state.allowances,
    leaves: state => state.leaves,
    ppes: state => state.ppes,
    trainings: state => state.trainings
  }
}
