import { map } from 'lodash';
import * as Msal from 'msal'

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_APP_ID,
    authority: 'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    clientSecret: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
}

// const msalInstance = new Msal.UserAgentApplication(msalConfig)
// msalInstance.handleRedirectCallback((error, response) => {
//   if (error) {
//     // console.log(error)
//   }

//   if (response) {
//     // console.log(response)
//   }
// })

// const protectedResourceMap = new Map();
//   protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
//   protectedResourceMap.set( 'https://graph.microsoft.com/v1.0/users', ['user.read']);
//   protectedResourceMap.set('https://localhost:44300/', ['https://cmms-test.azure.chevron.com/user_impersonation']);
  
const loginRequest = {
  scopes: ['user.read']
}

export default {
  msalInstance: null,
  /**
   * @return {Promise}
   */
  initialize() {
    this.msalInstance = new Msal.UserAgentApplication(msalConfig)
    return new Promise((resolve, reject) => {
      if (this.msalInstance.isCallback(window.location.hash) || window.self !== window.top) {
        // redirect to the location specified in the url params.
        this.msalInstance.handleWindowCallback()
      } else {
        // try pull the user out of local storage
        const token = this.isAuthenticated()
        const user = this.msalInstance.getAccount()
        if (token && user) {
          resolve(user)
        } else {
          resolve(user)
          // this.signIn()
        }
      }
    })
  },
  login() {
    this.msalInstance.loginRedirect(loginRequest)
    // .then(response => {
    //   localStorage.setItem('user-login', true);
    //   console.log('🚀 ~ file: msal-auth.js ~ line 23 ~ login ~ response', response)
    //   //console.log(msalInstance.getAccount())
    // }).catch(error => {
    //   console.log('🚀 ~ file: msal-auth.js ~ line 26 ~ login ~ error', error)
    // })
  },
  acquireToken() {
    // return new Promise((resolve, reject) => {
    //   this.msalInstance.acquireToken(loginRequest, (error, token) => {
    //     if (error || !token) {
    //       return reject(error)
    //     }
    //     return resolve(token)
    //   })
    // })
    const token = this.msalInstance.acquireTokenSilent(loginRequest)
    return token
  },
  refreshToken() {
    this.msalInstance.acquireTokenRedirect(loginRequest).then(response => {
      console.log(response)
    })
  },
  isAuthenticated() {
    const account = this.msalInstance.getAccount()
    // console.log('🚀 ~ file: msal-auth.js ~ line 38 ~ isAuthenticated ~ account', account)
    if (account) return true
    return false
  },
  signOut() {
    this.msalInstance.logout()
  }
}
