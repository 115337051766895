<template>
  <div>
   <router-link :to="link">
      <v-card
          class="pa-6 ma-3 card"
          color="primary"
          elevation="0"
        >
         <div class="card-body">
           <div class="d-flex flex-no-wrap align-items-center ">
              <div class=" card-icon icon iq-icon-box-2 mr-4 rtl-ml-2  rtl-mr-0">
                  <img class="iconEdit" :src="getImage(title)" alt="">
              </div>
              <div>
                <h2>{{count}}</h2>
                <h4>{{subTitle}}</h4>
                <div class="mt-1">
                  <p>

                  </p>
                </div>
              </div>
          </div>

         </div>
        </v-card>
   </router-link>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    },
    subTitle: {
      type: String
    },
    count: {
      type: String
    },
    link: {
      type: String
    }
  },
  data () {
    return {

    }
  },
  methods: {
    getImage (title) {
      let image = require('../../assets/icons/' + title + '.svg')
      return image
    }
  }

}
</script>
<style scoped>
.card-body{
      flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    text-align: right;
}
h4{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin: 0px;
    line-height: 1;
    color: #969696 !important;
    font-size: 1em;
    text-align: left;
}
h2{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 1;
    color: #333333;
    font-size: 1.4em;
    text-align: left;
}
p {
  font-size: 0.8rem;
  color: #757575;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: left;
}
.card {
  border-radius: 9px !important;
}
.card-icon {
  background-color:rgba(11, 45, 113, 0.14);
  border-radius: 100%;
  padding: 20px;
}
.iconEdit{
  width: 40px;
  height: 38px;
}
</style>
