import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/PaymentSchedules`

export default class PaymentScheduleService extends BaseService {
  async loadPaymentSchedule (id) {
    return this.sendRequest('GET', `${serviceUrl}/all/${id}`)
  }
  async loadPaymentSchedules (data) {
    return this.sendRequest('POST', `${serviceUrl}/all`, data)
  }
}
