import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/reports`

export default class ReportService extends BaseService {
  async dashboard () {
    return this.sendRequest('GET', `${serviceUrl}/dashbaord`)
  }
}
