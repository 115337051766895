<template>
  <div>
    <AppHeader />
    <AppNavigation />
    <SpinnerDialog v-bind="spinnerDialogData"></SpinnerDialog>
    <ConfirmationDialog v-bind="confirmationDialogData"></ConfirmationDialog>
    <InformationDialog v-bind="informationDialogData"></InformationDialog>
    <main class="container">
      <!-- COLUMN 1 -->
      <section class="column" id="column1">
        <div class="row">
          <div class="col">
            <!-- left column content (25% width) -->
          </div>
        </div>
      </section>

      <!-- COLUMN 2 -->
      <section class="column" id="column2">
        <div class="row">
          <div class="col">
            <!-- center column content (50% width) -->
            <router-view />
          </div>
        </div>
      </section>

      <!-- COLUMN 3 -->
      <section class="column" id="column3">
        <div class="row">
          <div class="col">
            <!-- right column content (25% width) -->
          </div>
        </div>
      </section>
    </main>
    <AppFooter />
    <div id="overlay">
      <div id="overlay-outerwrap">
        <a id="overlay-close"></a>
        <div id="overlay-wrap">
          <div id="overlay-wrapinside"></div>
        </div>
      </div>
    </div>
    <ScrollToTopButton></ScrollToTopButton>
  </div>
</template>

<script>

import LayoutMixin from '@/mixins/AppLayoutMixin.vue'

export default {
  name: 'Layout363',
  mixins: [LayoutMixin],
  data: () => ({
    layoutClass: 'layout-363'
  })
}
</script>
