import { stateService } from '../../../services'
import {
  CREATE_STATE,
  FETCH_STATE,
  FETCH_PENDING_STATE,
  APPROVE_STATE,
  UPDATE_STATE,
  DELETE_STATE
} from '../../action-type'

import {
  ADD_STATE,
  SET_STATE,
  SET_PENDING_STATE,
  SET_STATE__APPROVAL,
  EDIT_STATE,
  REMOVE_STATE
} from '../../mutation-type'

export default {
  state: {
    pendingStates: [],
    states: []
  },

  mutations: {
    [ADD_STATE] (state, payload) {
      state.states.push(payload)
    },
    [SET_STATE] (state, payload) {
      state.states = [...payload]
    },
    [SET_STATE__APPROVAL] (state, payload) {
      console.log('payload', payload)
      let index = null
      for (let i = 0; i < state.pendingStates.length; i++) {
        if (state.pendingStates[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingStates.splice(index, 1)
    },
    [SET_PENDING_STATE] (state, payload) {
      state.pendingStates = [...payload]
    },
    [EDIT_STATE] (state, payload) {

    },
    [REMOVE_STATE] (state, payload) {
      let index = null
      for (let i = 0; i < state.states.length; i++) {
        if (state.states[i].id === payload.id) {
          index = i
          break
        }
      }
      state.states.splice(index, 1)
    }
  },
  actions: {
    [CREATE_STATE] ({ commit }, state) {
      return new Promise((resolve, reject) => {
        stateService
          .createState(state)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [FETCH_STATE] ({ commit }) {
      return new Promise((resolve, reject) => {
        stateService
          .getAllStates()
          .then(result => {
            console.log(result)
            commit(SET_STATE, result.data.items)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [FETCH_PENDING_STATE] ({ commit }) {
      return new Promise((resolve, reject) => {
        stateService.getPendingState().then(result => {
          commit(SET_PENDING_STATE, result.data.items)
          resolve()
        })
          .catch(error => {
            reject(error)
          })
      })
    },
    [APPROVE_STATE] ({ commit }, department) {
      return new Promise((resolve, reject) => {
        stateService
          .stateStatus(department)
          .then(result => {
            commit(SET_STATE__APPROVAL, department)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [UPDATE_STATE] ({ commit }, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        stateService.updateState(data.id, data).then(result => {
          commit(EDIT_STATE, data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    [DELETE_STATE] ({ commit }, department) {
      return new Promise((resolve, reject) => {
        // departmentService.deleteDepartment(department).then(result => {
        //   commit(REMOVE_STATE, department)
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    }
  },
  getters: {
    states (state) {
      return state.states
    },
    pendingStates (state) {
      return state.pendingStates
    }
  }
}
