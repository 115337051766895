import { departmentService } from '../../../services'
import {
  CREATE_DEPARTMENT,
  FETCH_DEPARTMENT,
  FETCH_PENDING_DEPARTMENT,
  APPROVE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT
} from '../../action-type'

import {
  ADD_DEPARTMENT,
  SET_DEPARTMENT,
  SET_PENDING_DEPARTMENT,
  SET_DEPARTMENT__APPROVAL,
  EDIT_DEPARTMENT,
  REMOVE_DEPARTMENT
} from '../../mutation-type'

export default {
  state: {
    pendingDepartments: [],
    departments: []
  },

  mutations: {
    [ADD_DEPARTMENT] (state, payload) {
      state.departments.push(payload)
    },
    [SET_DEPARTMENT] (state, payload) {
      state.departments = [...payload]
    },
    [SET_DEPARTMENT__APPROVAL] (state, payload) {
      console.log('payload', payload)
      let index = null
      for (let i = 0; i < state.pendingDepartments.length; i++) {
        if (state.pendingDepartments[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingDepartments.splice(index, 1)
    },
    [SET_PENDING_DEPARTMENT] (state, payload) {
      state.pendingDepartments = [...payload]
    },
    [EDIT_DEPARTMENT] (state, payload) {

    },
    [REMOVE_DEPARTMENT] (state, payload) {
      let index = null
      for (let i = 0; i < state.departments.length; i++) {
        if (state.departments[i].id === payload.id) {
          index = i
          break
        }
      }
      state.departments.splice(index, 1)
    }
  },
  actions: {
    [CREATE_DEPARTMENT] ({ commit }, department) {
      return new Promise((resolve, reject) => {
        departmentService
          .createDepartment(department)
          .then(result => {
            console.log(result)
            commit(ADD_DEPARTMENT, department)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [FETCH_DEPARTMENT] ({ commit }) {
      return new Promise((resolve, reject) => {
        departmentService
          .getAllDepartments()
          .then(result => {
            localStorage.setItem('department', JSON.stringify(result.data.items))
            commit(SET_DEPARTMENT, result.data.items)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [FETCH_PENDING_DEPARTMENT] ({ commit }) {
      return new Promise((resolve, reject) => {
        departmentService.getPendingDepartments().then(result => {
          commit(SET_PENDING_DEPARTMENT, result.data.items)
          resolve()
        })
          .catch(error => {
            reject(error)
          })
      })
    },
    [APPROVE_DEPARTMENT] ({ commit }, department) {
      return new Promise((resolve, reject) => {
        departmentService
          .approveDepartment(department)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [UPDATE_DEPARTMENT] ({ commit }, department) {
      return new Promise((resolve, reject) => {
        departmentService.updateDepartment(department).then(result => {
          commit(EDIT_DEPARTMENT, department)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    [DELETE_DEPARTMENT] ({ commit }, department) {
      return new Promise((resolve, reject) => {
        departmentService.deleteDepartment(department).then(result => {
          commit(REMOVE_DEPARTMENT, department)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    departments (state) {
      return state.departments
    },
    pendingDepartments (state) {
      return state.pendingDepartments
    }
  }
}
