<template>
  <div class="modal" :id="id" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="modal-title">
            <img src="../../assets/ajax-loader.gif" />
            &nbsp;Please Wait...
            <span class="d-block mt-4">
              <strong>{{ message }}</strong>
            </span>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpinnerDialog',
  props: {
    id: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    async isActive () {
      // const self = this
      // if (self.isActive) {
      //   await $(`#${self.id}`).modal({
      //     backdrop: 'static', // remove ability to close modal with click
      //     keyboard: false, // remove option to close with keyboard
      //     show: true
      //   })
      // } else {
      //   await $(`#${self.id}`).modal('hide')
      // }
    }
  }
}
</script>
