import { contractEmployerService } from '../../../services'
import {
  CREATE_CONTRACTEMPLOYER,
  FETCH_CONTRACTEMPLOYER,
  APPROVE_CONTRACTEMPLOYER,
  FETCH_PENDING_CONTRACTEMPLOYER,
  UPDATE_CONTRACTEMPLOYER,
  DELETE_CONTRACTEMPLOYER
} from '../../action-type'

import {
  ADD_CONTRACTEMPLOYER,
  SET_CONTRACTEMPLOYER,
  SET_CONTRACTEMPLOYER__APPROVAL,
  SET_PENDING_CONTRACTEMPLOYER,
  EDIT_CONTRACTEMPLOYER,
  REMOVE_CONTRACTEMPLOYER

} from '../../mutation-type'

export default {
  state: {
    contractEmployers: [],
    pendingContractEmployers: []
  },

  mutations: {
    [ADD_CONTRACTEMPLOYER] (state, payload) {
      state.contractEmployers.push(payload)
    },
    [SET_CONTRACTEMPLOYER] (state, payload) {
      state.contractEmployers = [...payload]
    },
    [SET_PENDING_CONTRACTEMPLOYER] (state, payload) {
      state.pendingContractEmployers = [...payload]
    },
    [SET_CONTRACTEMPLOYER__APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingContractEmployers.length; i++) {
        if (state.pendingContractEmployers[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingContractEmployers.splice(index, 1)
    },
    [REMOVE_CONTRACTEMPLOYER] (state, payload) {
      let index = null
      for (let i = 0; i < state.contractEmployers.length; i++) {
        if (state.contractEmployers[i].id === payload) {
          index = i
          break
        }
      }
      state.contractEmployers.splice(index, 1)
    },
    [EDIT_CONTRACTEMPLOYER] (state, payload) {

    }
  },
  actions: {
    async [CREATE_CONTRACTEMPLOYER] ({ commit }, contractEmployer) {
      return new Promise((resolve, reject) => {
        contractEmployerService
          .createContractEmployer(contractEmployer)
          .then(result => {
            commit(ADD_CONTRACTEMPLOYER, contractEmployer)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async [FETCH_CONTRACTEMPLOYER] ({ commit }) {
      return new Promise((resolve, reject) => {
        contractEmployerService
          .getAllContractEmployers()
          .then(result => {
            commit(SET_CONTRACTEMPLOYER, result.data.items)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    async [FETCH_PENDING_CONTRACTEMPLOYER] ({ commit }) {
      return new Promise((resolve, reject) => {
        contractEmployerService.getPendingContractEmployers().then(result => {
          commit(SET_PENDING_CONTRACTEMPLOYER, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_CONTRACTEMPLOYER] ({ commit }, contractEmployer) {
      return new Promise((resolve, reject) => {
        contractEmployerService
          .approveContractEmployer(contractEmployer)
          .then(result => {
            commit(SET_CONTRACTEMPLOYER__APPROVAL, contractEmployer)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async [UPDATE_CONTRACTEMPLOYER] ({ commit }, id, contractEmployer) {
      console.log('value', contractEmployer)
      return new Promise((resolve, reject) => {
        contractEmployerService.updateContractEmployer(id, contractEmployer).then(result => {
          commit(EDIT_CONTRACTEMPLOYER, contractEmployer)
          resolve()
        }).catch(error => {
          console.log('this', error)
          reject(error)
        })
      })
    },
    async [DELETE_CONTRACTEMPLOYER] ({ commit }, id) {
      console.log('value', id)
      return new Promise((resolve, reject) => {
        contractEmployerService.deleteContractEmployeer(id).then(result => {
          commit(REMOVE_CONTRACTEMPLOYER, id)
          resolve()
        }).catch(error => {
          console.log('this', error)
          reject(error)
        })
      })
    }

  },
  getters: {
    contractEmployers (state) {
      return state.contractEmployers
    },
    pendingContractEmployers (state) {
      return state.pendingContractEmployers
    },
    contractEmployer (state) {
      return (id) => {
        return state.contractEmployers.find((employer) => {
          return employer.id === id
        })
      }
    }
  }
}
