import BaseService from './baseService'
const serviceURL = '/api/v1/account'

export default class AccountService extends BaseService {
  async login (msGraphToken) {
    return this.sendRequest('POST', `${serviceURL}/SignIn`, null)
  }

  async customLogin (payload) {
    return this.sendNoAuthRequest('POST', `${serviceURL}/CustomLogin`, payload)
  }

  async getUserRole (username) {
    return this.sendNoAuthRequest('GET', `${serviceURL}/GetUserRole/?username=${username}`)
  }

  async verifyUserToken (token) {
    return this.sendNoAuthRequest('POST', `${serviceURL}/ValidateUserToken/?token=${token}`)
  }

  async validateNewUser (username) {
    return this.sendNoAuthRequest('POST', `${serviceURL}/validateUserInfo/?username=${username}`)
  }
}
