export const FETCH_ROLES = 'fetchRoles'
export const CREATE_ROLE = 'createRole'
export const UPDATE_ROLE = 'updateRole'
export const DELETE_ROLE = 'deleteRole'

export const FETCH_USERS = 'fetchUsers'
export const CREATE_USER = 'createUser'
export const UPDATE_USER = 'updateUser'
export const FETCH_PENDING_USERS = 'fetchPendingUsers'
export const APPROVE_USER = 'approveUser'

export const FETCH_LOCATION = 'fetchLocation'
export const FETCH_PENDING_LOCATION = 'fetchPendingLocation'
export const CREATE_LOCATION = 'createLocation'
export const UPDATE_LOCATION = 'updateLocation'
export const DELETE_LOCATION = 'deleteLocation'
export const APPROVE_LOCATION = 'approveLocation'
export const REJECT_LOCATION = 'rejectLocation'

export const FETCH_EMPLOYEES = 'fetchEmployees'
export const CREATE_EMPLOYEES = 'createEmployees'
export const FETCH_PENDING_EMPLOYEES = 'fetchPendingEmployees'
export const APPROVE_EMPLOYEE = 'approveEmployee'
export const REJECT_EMPLOYEE = 'rejectEmployee'
export const RETURN_EMPLOYEE = 'returnEmployee'
export const FETCH_EMPLOYEES_SEARCH = 'fetchEmployeeSearch'

export const CREATE_DEPARTMENT = 'createDepartment'
export const FETCH_DEPARTMENT = 'fetchDepartment'
export const FETCH_PENDING_DEPARTMENT = 'fetchPendingDepartment'
export const UPDATE_DEPARTMENT = 'updateDepartment'
export const DELETE_DEPARTMENT = 'deleteDepartment'
export const APPROVE_DEPARTMENT = 'approveDepartment'
export const REJECT_DEPARTMENT = 'rejectDepartment'

export const CREATE_JOBTITLE = 'createJobTitle'
export const FETCH_JOBTITLE = 'fetchJobTitle'
export const FETCH_PENDING_JOBTITLE = 'fetchPendingJobTitle'
export const UPDATE_JOBTITLE = 'updateJobTitle'
export const DELETE_JOBTITLE = 'deleteJobTitle'
export const APPROVE_JOBTITLE = 'approveJobtitle'
export const REJECT_JOBTITLE = 'rejectJobtitle'
export const FETCH_PENDING_UNIONJOBTITLESALARIES = 'fetchPendingJobTitleSalaries'
export const APPROVE_UNIONJOBTITLESALARIES = 'approveJobTitleSalaries'

export const CREATE_WORKAREA = 'createWorkArea'
export const FETCH_WORKAREA = 'fetchWorkArea'
export const FETCH_PENDING_WORKAREA = 'fetchPendingWorkArea'
export const UPDATE_WORKAREA = 'updateWorkArea'
export const DELETE_WORKAREA = 'deleteWorkArea'
export const APPROVE_WORKAREA = 'approveWorkArea'
export const REJECT_WORKAREA = 'rejectWorkArea'

export const CREATE_CONTRACTEMPLOYER = 'createWorkEmployer'
export const FETCH_CONTRACTEMPLOYER = 'fetchWorkEmployer'
export const FETCH_PENDING_CONTRACTEMPLOYER = 'fetchPendingWorkEmployer'
export const UPDATE_CONTRACTEMPLOYER = 'updateContractEmployer'
export const DELETE_CONTRACTEMPLOYER = 'deleteContractEmployer'
export const APPROVE_CONTRACTEMPLOYER = 'approveContractEmployer'
export const REJECT_CONTRACTEMPLOYER = 'rejectContractEmployer'

export const CREATE_SALARY = 'createSalary'
export const FETCH_SALARY = 'fetchSalary'
export const FETCH_PENDING_SALARY = 'fetchPendingSalary'
export const UPDATE_SALARY = 'updateSalary'
export const DELETE_SALARY = 'deleteSalary'
export const APPROVE_SALARY = 'approveSalary'
export const REJECT_SALARY = 'rejectSalary'

export const CREATE_BENEFIT = 'createBenefits'
export const FETCH_BENEFIT = 'fetchBenefits'
export const FETCH_PENDING_BENEFIT = 'fetchPendingBenefits'
export const UPDATE_BENEFIT = 'updateBenefits'
export const DELETE_BENEFIT = 'deleteBenefits'
export const APPROVE_BENEFIT = 'approveBenefits'
export const REJECT_BENEFIT = 'rejectBenefits'

export const CREATE_ROTATION = 'createRotation'
export const FETCH_ROTATION = 'fetchRotation'
export const FETCH_PENDING_ROTATION = 'fetchPendingRotation'
export const UPDATE_ROTATION = 'updateRotation'
export const DELETE_ROTATION = 'deleteRotation'
export const APPROVE_ROTATION = 'approveRotation'
export const REJECT_ROTATION = 'rejectRotation'

export const CREATE_REQUEST = 'createRequest'
export const FETCH_REQUEST = 'fetchRequest'
export const FETCH_PENDING_REQUEST = 'fetchPendingRequest'
export const UPDATE_REQUEST = 'updateRequest'
export const DELETE_REQUEST = 'deleteRequest'
export const APPROVE_REQUEST = 'approveRequest'
export const APPROVE_BULK_REQUEST = 'approveBulkRequest'
export const APPROVE_BULK_LEAVE = 'approveBulkLeave'
export const APPROVE_BULK_PPE = 'approveBulkPpe'
export const APPROVE_BULK_TRAINING = 'approveBulkTraining'
export const REJECT_REQUEST = 'rejectRequest'
export const FETCH_PENDING_PPES = 'fetchPendingPpes'
export const FETCH_PENDING_TRAINING = 'fetchPendingTraining'
export const FETCH_PENDING_ALLOWANCES = 'fetchPendingAllowances'
export const FETCH_PENDING_LEAVE = 'fetchPendingLeave'
export const FETCH_ALLOWANCE = 'fetchAllowance'
export const FETCH_LEAVE = 'fetchLeave'
export const FETCH_PPE = 'fetchPPE'
export const FETCH_TRAINING = 'fetchTraining'
export const FORWARD_REQUEST = 'forwardRequest'

export const APPROVE_REQUISITION = 'requisitionApproval'
export const REJECT_REQUISITION = 'rejectRequisition'

export const FETCH_REQUISITION = 'fetchRequisition'

export const CREATE_TIMESHEET = 'createTimesheet'
export const SAVE_TIMESHEET = 'saveTimesheet'
export const FETCH_TIMESHEET = 'fetchTimesheet'
export const APPROVE_TIMESHEET = 'approveTimesheet'
export const UPLOAD_TIMESHEET = 'uploadTimesheet'

export const CREATE_EXIT = 'createExit'

export const CREATE_STATE = 'createState'
export const FETCH_STATE = 'fetchState'
export const FETCH_PENDING_STATE = 'fetchPendingState'
export const UPDATE_STATE = 'updateState'
export const DELETE_STATE = 'deleteState'
export const APPROVE_STATE = 'approveState'
export const REJECT_STATE = 'rejectState'

export const CREATE_TERMINALRATE = 'createTerminalRate'
export const FETCH_TERMINALRATE = 'fetchTerminalRate'
export const FETCH_PENDING_TERMINALRATE = 'fetchPendingTerminalRate'
export const UPDATE_TERMINALRATE = 'updateTerminalRate'
export const DELETE_TERMINALRATE = 'deleteTerminalRate'
export const APPROVE_TERMINALRATE = 'approveTerminalRate'
export const REJECT_TERMINALRATE = 'rejectTerminalRate'

export const CREATE_MEDICAL_RECORDS = 'createMedicalRecords'
export const CREATE_TERMINALBENEFIT = 'createTerminalBenefit'

export const CREATE_REQUISITION = 'createRequisition'

export const FETCH_HOLIDAY = 'fetchHoliday'
export const FETCH_PENDING_HOLIDAY = 'fetchPendingHoliday'
export const UPDATE_HOLIDAY = 'updateHoliday'
export const DELETE_HOLIDAY = 'deleteHoliday'
export const APPROVE_HOLIDAY = 'approveHoliday'
export const REJECT_HOLIDAY = 'rejectHoliday'

export const FETCH_CHARGE_CODE = 'fetchChargeCode'
export const FETCH_ORGANISATION = 'fetchOrganisation'

export const FETCH_DASHBOARD_REPORT = 'fetchDashboardReport'
