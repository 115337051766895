import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/medicalscreeningcategory`

export default class MedicalScreeningCategoryService extends BaseService {
  async getMedicalScreeningCategory () {
    return this.sendRequest('GET', `${serviceUrl}`)
  }

  async saveMedicalScreeningCategory (request) {
    return this.sendRequest('POST', `${serviceUrl}`, request)
  }

  async deleteMedicalScreeningCategory (id) {
    return this.sendRequest('DELETE', `${serviceUrl}/${id}`)
  }
}
