import { benefitSetupService } from '../../../services'
import {
  CREATE_BENEFIT,
  FETCH_PENDING_BENEFIT,
  APPROVE_BENEFIT,
  FETCH_BENEFIT
} from '../../action-type'

import {
  SET_PENDING_BENEFIT,
  SET_BENEFIT__APPROVAL,
  SET_BENEFIT
} from '../../mutation-type'

export default {
  state: {
    pendingBenefitSetup: [],
    benefitSetup: []
  },
  mutations: {
    [SET_PENDING_BENEFIT] (state, payload) {
      state.pendingBenefitSetup = [...payload]
    },
    [SET_BENEFIT] (state, payload) {
      state.benefitSetup = payload
    },
    [SET_BENEFIT__APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingBenefitSetup.length; i++) {
        if (state.pendingBenefitSetup[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingBenefitSetup.splice(index, 1)
    }
  },
  actions: {
    async [CREATE_BENEFIT] ({ commit }, benefit) {
      return new Promise((resolve, reject) => {
        benefitSetupService.createBenefit(benefit).then(() => {
          console.log('successful')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_PENDING_BENEFIT] ({ commit }) {
      return new Promise((resolve, reject) => {
        benefitSetupService.getPendingBenefits().then((result) => {
          commit(SET_PENDING_BENEFIT, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_BENEFIT] ({ commit }, benefit) {
      return new Promise((resolve, reject) => {
        benefitSetupService.approveBenefits(benefit).then(result => {
          commit(SET_BENEFIT__APPROVAL, benefit)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_BENEFIT] ({ commit }) {
      return new Promise((resolve, reject) => {
        benefitSetupService.getBenefitSetupAll().then(result => {
          commit(SET_BENEFIT, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    pendingBenefitSetup (state) {
      return state.pendingBenefitSetup
    },
    benefitSetup: state => state.benefitSetup

  }
}
