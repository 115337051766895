import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/medicalrecord`

export default class MedicalService extends BaseService {
  async getMedicalRecords () {
    return this.sendRequest('GET', `${serviceUrl}`)
  }

  async saveMedicalRecords (request) {
    return this.sendRequest('POST', `${serviceUrl}`, request)
  }

  async deleteMedicalRecords (request) {
    return this.sendRequest('DELETE', `${serviceUrl}`, request)
  }
}
