import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/department`

export default class DepartmentService extends BaseService {
  async getAllDepartments () {
    return this.sendRequest('GET', `${serviceURL}/all`)
  }
  async createDepartment (department) {
    return this.sendRequest('POST', `${serviceURL}/new`, department)
  }
  async getPendingDepartments () {
    return this.sendRequest('GET', `${serviceURL}/pending`)
  }
  async getDepartment (id) {
    return this.sendRequest('GET', `${serviceURL}/${id}`)
  }
  async approveDepartment (department) {
    return this.sendRequest('POST', `${serviceURL}/audit/approve`, department)
  }
  async updateDepartment (department) {
    return this.sendRequest('POST', `${serviceURL}/update`, department)
  }
  async deleteDepartment (department) {
    return this.sendRequest('POST', `${serviceURL}/delete`, department)
  }
}
