import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/rotation`

export default class RotationService extends BaseService {
  async createRotation (rotation) {
    return this.sendRequest('POST', `${serviceURL}/new`, rotation)
  }
  async getAllRotation () {
    return this.sendRequest('GET', `${serviceURL}/all`)
  }
  async getPendingRotaion () {
    return this.sendRequest('GET', `${serviceURL}/pending`)
  }
  async approveRotation (rotation) {
    return this.sendRequest('POST', `${serviceURL}/audit/approve`, rotation)
  }
  async rejectRotation (rotation) {
    return this.sendRequest('Post', `${serviceURL}/audit/reject`)
  }
  async getRotation (id) {
    return this.sendRequest('GET', `${serviceURL}/${id}`)
  }
  async updateRotation (rotation) {
    return this.sendRequest('POST', `${serviceURL}/update`, rotation)
  }
  async deleteRotation (id) {
    return this.sendRequest('POST', `${serviceURL}/delete/${id}`)
  }
}
