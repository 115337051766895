import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/holiday`

export default class HolidayService extends BaseService {
  async createHoliday (data) {
    return this.sendRequest('POST', `${serviceUrl}`, data)
  }
  async getPendingHoliday () {
    return this.sendRequest('GET', `${serviceUrl}/pending/all`)
  }
  async approveHoliday (data) {
    return this.sendRequest('POST', `${serviceUrl}/pending/approve`, data)
  }
  async getHoliday (data) {
    return this.sendRequest('GET', `${serviceUrl}`)
  }
  async deleteHoliday (id) {
    return this.sendRequest('POST', `${serviceUrl}/delete/${id}`)
  }
}
