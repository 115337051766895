const Status = {
  Inactive: {
    value: 0,
    description: 'Not Active'
  },
  Active: {
    value: 1,
    description: 'Active'
  }
}

const RoleRights = {
  ManageRoles: {
    value: 1,
    description: 'Manage roles'
  },
  ManagerUsers: {
    value: 2,
    description: 'Manage users'
  }
}

class Enums {
  static convertToEnum (enumType, value) {
    for (const enumProperty in enumType) {
      if (enumType[enumProperty].value === value) {
        return enumType[enumProperty]
      }
    }
    return null
  }
}

Object.freeze(Status)
Object.freeze(RoleRights)

Object.defineProperty(Enums, 'Status', {
  value: Status,
  writable: false
})

Object.defineProperty(Enums, 'RoleRights', {
  value: RoleRights,
  writable: false
})

Object.freeze(Enums)
export default Enums
