import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/contractemployeruser`

export default class ContractEmployeeruser extends BaseService {
  async getUsers () {
    return this.sendRequest('GET', `${serviceUrl}`)
  }

  async createContractEmployer (contractEmployer) {
    return this.sendRequest('POST', `${serviceUrl}`, contractEmployer)
  }

  async changePassword (contractEmployer) {
    return this.sendRequest('POST', `${serviceUrl}/update-password`, contractEmployer)
  }

  async isUserExist (username) {
    return this.sendRequest('GET', `${serviceUrl}/is-user-exist/${username}`)
  }

  async deleteContractEmployerUser (id) {
    return this.sendRequest('DELETE', `${serviceUrl}/delete-user/${id}`)
  }
}
