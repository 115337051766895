import { holidayService } from '../../../services'
import {
  FETCH_PENDING_HOLIDAY,
  APPROVE_HOLIDAY,
  FETCH_HOLIDAY,
  UPDATE_HOLIDAY,
  DELETE_HOLIDAY
} from '../../action-type'
import { SET_PENDING_HOLIDAY, EDIT_HOLIDAY, REMOVE_HOLIDAY, SET_HOLIDAY_APPROVAL, SET_HOLIDAY } from '../../mutation-type'

export default {
  state: {
    holidays: [],
    pendingHolidays: []
  },
  mutations: {
    [SET_PENDING_HOLIDAY] (state, payload) {
      state.pendingHolidays = [...payload]
    },
    [SET_HOLIDAY] (state, payload) {
      state.holidays = [...payload]
    },
    [EDIT_HOLIDAY] (state, payload) {
      let newArray = state.holidays.filter(hl => hl.id !== payload.id)
      newArray.push(payload)
      console.log('newArray', newArray)
      console.log('payload', payload)
      state.holidays = newArray
    },
    [SET_HOLIDAY_APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingHolidays.length; i++) {
        if (state.pendingHolidays[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingHolidays.splice(index, 1)
    },
    [REMOVE_HOLIDAY] (state, payload) {
      let newArray = state.holidays.filter(hl => hl.id !== payload.id)
      newArray.push(payload)
      console.log('newArray', newArray)
      console.log('payload', payload)
      state.holidays = newArray
    }
  },
  actions: {
    [APPROVE_HOLIDAY] ({ commit }, holiday) {
      return new Promise((resolve, reject) => {
        holidayService
          .approveHoliday(holiday)
          .then(result => {
            commit(SET_HOLIDAY_APPROVAL, holiday)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    [FETCH_PENDING_HOLIDAY] ({ commit }) {
      return new Promise((resolve, reject) => {
        holidayService.getPendingHoliday().then(result => {
          commit(SET_PENDING_HOLIDAY, result.data.items)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    [FETCH_HOLIDAY] ({ commit }) {
      return new Promise((resolve, reject) => {
        holidayService.getHoliday().then(result => {
          commit(SET_HOLIDAY, result.data.items)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    [UPDATE_HOLIDAY] ({ commit }, data) {
      return new Promise((resolve, reject) => {
        holidayService.createHoliday(data).then(result => {
          resolve()
          console.log(result.data)
          commit(EDIT_HOLIDAY, result.data)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    [DELETE_HOLIDAY] ({ commit }, data) {
      return new Promise((resolve, reject) => {
        holidayService.deleteHoliday(data).then(result => {
          console.log(result)
          resolve()
          commit(REMOVE_HOLIDAY, result.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    holidays (state) {
      return state.holidays
    },
    pendingHolidays (state) {
      return state.pendingHolidays
    }
  }
}
