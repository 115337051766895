import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/chargecode`

export default class ChargeCodeService extends BaseService {
  async getAllChargeCodes () {
    return this.sendRequest('GET', `${serviceURL}/all`)
  }
}
