import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/medicalscreening`

export default class MedicalScreeningService extends BaseService {
  async getMedicalScreenings () {
    return this.sendRequest('GET', `${serviceUrl}`)
  }

  async saveMedicalScreenings (request) {
    return this.sendRequest('POST', `${serviceUrl}`, request)
  }

  async deleteMedicalScreening (id) {
    return this.sendRequest('DELETE', `${serviceUrl}/${id}`)
  }

  async pendingMedicalScreening () {
    return this.sendRequest('GET', `${serviceUrl}/pending`)
  }

  async approveMedicalScreenings (request) {
    return this.sendRequest('POST', `${serviceUrl}/approval/bulk`, request)
  }

  async cancelMedicalScreening (id) {
    return this.sendRequest('DELETE', `${serviceUrl}/cancel/${id}`)
  }

  async forwardMedicalScreening (request) {
    return this.sendRequest('POST', `${serviceUrl}/forward`, request)
  }
}
