'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/employeededuction`

export default class EmployeeService extends BaseService {
  async getEmployeeDeductions () {
    return this.sendRequest('GET', `${serviceURL}`)
  }
  async getPendingEmployeeDeductions () {
    return this.sendRequest('GET', `${serviceURL}/pending`)
  }
  async getEmployeeDeduction (id) {
    return this.sendRequest('GET', `${serviceURL}/${id}`)
  }
  async saveEmployeeDeduction (data) {
    return this.sendRequest('POST', `${serviceURL}`, data)
  }
  async approveEmployeeDeduction (data) {
    return this.sendRequest('POST', `${serviceURL}/approve`, data)
  }
  async rejectEmployeeDeduction (data) {
    return this.sendRequest('POST', `${serviceURL}/reject`, data)
  }
  async deleteEmployeeDeduction (id) {
    return this.sendRequest('DELETE', `${serviceURL}/delete/${id}`)
  }
}
