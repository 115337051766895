<script>
import AppHeader from '@/components/layout/AppHeader.vue'
import AppNavigation from '@/components/layout/AppNavigation.vue'
import AppFooter from '@/components/layout/AppFooter.vue'
import ScrollToTopButton from '@/components/layout/ScrollToTopButton.vue'
import SpinnerDialog from '@/components/dialog/SpinnerDialog.vue'
import ConfirmationDialog from '@/components/dialog/ConfirmationDialog.vue'
import InformationDialog from '@/components/dialog/InformationDialog.vue'

import Events from '@/utils/events'

export default {
/* global $ */
  components: {
    AppHeader,
    AppNavigation,
    AppFooter,
    ScrollToTopButton,
    SpinnerDialog,
    ConfirmationDialog,
    InformationDialog
  },
  inject: ['eventBus'],
  data: () => ({
    spinnerDialogData: {
      id: 'spinner',
      message: '',
      isActive: false
    },
    informationDialogData: {
      id: 'InformationDialog',
      title: '',
      message: '',
      okLabel: 'ok',
      okEvent: null,
      reference: null,
      isActive: false
    },
    confirmationDialogData: {
      id: 'ConfirmationDialog',
      title: '',
      label: '',
      message: '',
      yesLabel: 'yes',
      noLabel: 'no',
      yesCallback: null,
      noCallback: null,
      isActive: false
    }
  }),
  methods: {
    showSpinner (message) {
      this.spinnerDialogData.message = message
      this.spinnerDialogData.isActive = true
    },
    closeSpinner () {
      this.spinnerDialogData.message = ''
      this.spinnerDialogData.isActive = false
    },
    showInformationDialog (dialogData) {
      this.informationDialogData.title = dialogData.title
      this.informationDialogData.message = dialogData.message
      this.informationDialogData.okCallback = dialogData.okCallback
      this.informationDialogData.isActive = true
    },
    closeInformationDialog () {
      this.informationDialogData.title = ''
      this.informationDialogData.message = ''
      this.informationDialogData.okCallback = null
      this.informationDialogData.isActive = false
    },
    openConfirmationDialog (dialogData) {
      this.confirmationDialogData.title = dialogData.title
      this.confirmationDialogData.label = dialogData.label
      this.confirmationDialogData.message = dialogData.message
      this.confirmationDialogData.yesCallback = dialogData.yesCallback
      this.confirmationDialogData.noCallback = dialogData.noCallback
      this.confirmationDialogData.yesLabel = dialogData.yesLabel ? dialogData.yesLabel : 'yes'
      this.confirmationDialogData.noLabel = dialogData.noLabel ? dialogData.noLabel : 'no'
      this.confirmationDialogData.isActive = true
    },
    closeConfirmationDialog () {
      this.confirmationDialogData.title = ''
      this.confirmationDialogData.label = ''
      this.confirmationDialogData.message = ''
      this.confirmationDialogData.yesCallback = null
      this.confirmationDialogData.noCallback = null
      this.confirmationDialogData.isActive = false
    }
  },
  mounted () {
    $('body').removeClass((index, className) => (className.match(/(^|\s)layout-\S+/g) || []).join(' '))
      .addClass(this.layoutClass)

    this.eventBus.$on(Events.LongOperationStarted, this.showSpinner)
    this.eventBus.$on(Events.LongOperationCompleted, this.closeSpinner)
    this.eventBus.$on(Events.OpenConfirmationDialog, this.openConfirmationDialog)
    this.eventBus.$on(Events.CloseConfirmationDialog, this.closeConfirmationDialog)
    this.eventBus.$on(Events.OpenInformationDialog, this.showInformationDialog)
    this.eventBus.$on(Events.CloseInformationDialog, this.closeInformationDialog)
  },
  beforeDestroy () {
    this.eventBus.$off(Events.LongOperationStarted, this.showSpinner)
    this.eventBus.$off(Events.LongOperationCompleted, this.closeSpinner)
    this.eventBus.$off(Events.OpenConfirmationDialog, this.openConfirmationDialog)
    this.eventBus.$off(Events.CloseConfirmationDialog, this.closeConfirmationDialog)
    this.eventBus.$off(Events.OpenInformationDialog, this.showInformationDialog)
    this.eventBus.$off(Events.CloseInformationDialog, this.closeInformationDialog)
  }
}
</script>
