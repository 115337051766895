<template>
  <div>
    <v-btn v-if="label == 'approve'"
           class="btn btnSubmit"
           @click.prevent="onClick"
           :disabled="disabled"
           :loading="isLoading">
      <v-icon dark>
        mdi-checkbox-marked-circle-outline
      </v-icon>
    </v-btn>

    <v-btn v-else-if="label == 'forward'"
           class="btn btnForward"
           @click.prevent="onClick"
           :disabled="disabled"
           :color="color"
           :loading="isLoading">
      <v-icon dark>
        mdi-share
      </v-icon>
    </v-btn>

    <v-icon v-if="btnType==='Icon'"
            class="btn btnSubmit"
            @click.prevent="onClick"
            :disabled="disabled"
            :loading="isLoading"
            small color="green">mdi-share</v-icon>

    <!--<v-btn v-else-if="label == 'view'"
           class="btn"
           @click.prevent="onClick"
           :disabled="disabled"
           :loading="isLoading">
      <v-icon dark>
        mdi-eye-settings-outline
      </v-icon>
    </v-btn>-->

        <v-btn v-else-if="btnType==='Forward'"
               class="btn"
               :color="color"
               @click.prevent="onClick"
               :disabled="disabled"
               :loading="isLoading">
          {{label}}
        </v-btn>

    <v-btn v-else-if="btnType==='Submit'"
           class="btn btnSubmit"
           :color="color"
           @click.prevent="onClick"
           :disabled="disabled"
           :loading="isLoading">
      {{label}}
    </v-btn>

    <!--<v-btn v-if="label==='reject'"
           class="btn btnCancel"
           @click.prevent="onClick"
           :disabled="disabled"
           :loading="isLoading">
      <v-icon dark>
        mdi-close-box-outline
      </v-icon>
    </v-btn>-->

    <v-btn v-else-if="btnType==='Cancel'"
           class="btn btnCancel"
           @click.prevent="onClick"
           :disabled="disabled"
           :loading="isLoading">{{label}}</v-btn>

  </div>
</template>
<script>
export default {
  props: {
    btnType: {
      type: String
    },
    label: {
      type: String
    },
    color: {
      type: String
    },
    // onClick: {
    //   type: Function,
    //   required: true
    // },
    disabled: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    }
  },
  methods: {
    onClick () {
      this.$emit('onClick')
    }
  }
}
</script>
<style scoped>
  .btnSubmit {
    background: #0066b2 !important;
  }

  .btnForward {
    background: #4cff00 !important;
  }

  .btnCancel {
    background-color: #dc3545 !important;
  }

  .btn {
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: "GothamBold",Arial,sans-serif;
    font-size: 12px;
    padding: 8px 20px;
    text-decoration: none;
    border-bottom-style: none;
    border-radius: 0;
    text-transform: unset !important;
  }
</style>
