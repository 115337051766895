<script>
import Header from '@/components/layout/AppHeader.vue'
import Footer from '@/components/layout/AppFooter.vue'

export default {
  components: {
    Header,
    Footer
  },
  mounted () {
    $('body').removeClass((index, className) => (className.match(/(^|\s)layout-\S+/g) || []).join(' '))
      .addClass('layout-12')
    NextPage.initialize()
  }
}

</script>
<template>
  <div id="app">
    <Header />
    <main class="container">
      <section class="column" id="column1">
        <div>
          <h4 class="text-primary">Authenticating...</h4>
          <v-progress-circular
            indeterminate
            color="primary">
          </v-progress-circular>
        </div>
      </section>
    </main>
    <Footer />
    <div id="overlay">
      <div id="overlay-outerwrap">
        <a id="overlay-close"></a>
        <div id="overlay-wrap">
          <div id="overlay-wrapinside"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
    font-weight: bold;
    color: #2c3e50;
  }
  .router-link-exact-active {
    color: #42b983;
  }
</style>
