import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/location`

export default class LocationService extends BaseService {
  async getLocations () {
    return this.sendRequest('GET', `${serviceUrl}/all`)
  }
  async createLocation (location) {
    return this.sendRequest('POST', `${serviceUrl}/new`, location)
  }
  async getPendingLocations () {
    return this.sendRequest('GET', `${serviceUrl}/pending`)
  }
  async getLocation (id) {
    return this.sendRequest('GET', `${serviceUrl}/${id}`)
  }
  async approveLocation (location) {
    return this.sendRequest('POST', `${serviceUrl}/audit/approve`, location)
  }
  async updateLocation (location) {
    return this.sendRequest('POST', `${serviceUrl}/update`, location)
  }
  async deleteLocation (location) {
    return this.sendRequest('POST', `${serviceUrl}/delete`, location)
  }
}
