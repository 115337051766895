import Vue from 'vue'
import Vuex from 'vuex'

import LayoutModule from '@/store/modules/layout'
import UserModule from '@/store/modules/user'
import RoleModule from '@/store/modules/role'
import LocationModule from '@/store/modules/location'
import EmployeeModule from '@/store/modules/employee'
import DepartmentModule from '@/store/modules/department'
import WorkAreaModule from '@/store/modules/workArea'
import JobTitleModule from '@/store/modules/jobTitle'
import ContractEmployerModule from '@/store/modules/contractEmployer'
import SalaryComponentsModule from '@/store/modules/salaryComponent'
import BenefitSetupModule from '@/store/modules/benefitSetup'
import RotationModule from '@/store/modules/rotation'
import RequestsModule from '@/store/modules/requests'
import TimesheetModule from '@/store/modules/timesheet'
import Benefits from '@/store/modules/benefits'
import StateModule from '@/store/modules/state'
import EmployeeRequisitionModule from '@/store/modules/employeeRequisition'
import HolidayModule from '@/store/modules/holiday'
import NMAIntegration from '@/store/modules/nmaIntegration'
import ReportModule from '@/store/modules/reports'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    layout: LayoutModule,
    userModule: UserModule,
    roleModule: RoleModule,
    locationModule: LocationModule,
    employeeModule: EmployeeModule,
    departmentModule: DepartmentModule,
    workAreaModule: WorkAreaModule,
    jobTitleModule: JobTitleModule,
    contractEmployerModule: ContractEmployerModule,
    salaryComponentsModule: SalaryComponentsModule,
    benefitSetupModule: BenefitSetupModule,
    rotationModule: RotationModule,
    requestsModule: RequestsModule,
    timesheetModule: TimesheetModule,
    benefits: Benefits,
    stateModule: StateModule,
    employeeRequisitionModule: EmployeeRequisitionModule,
    holidayModule: HolidayModule,
    nmaIntegration: NMAIntegration,
    reportModule: ReportModule
  }
})
