<template>
  <div>
    <AppHeader />
    <AppNavigation />
    <SpinnerDialog v-bind="spinnerDialogData"></SpinnerDialog>
    <ConfirmationDialog v-bind="confirmationDialogData"></ConfirmationDialog>
    <InformationDialog v-bind="informationDialogData"></InformationDialog>
    <main class="container">
      <!-- COLUMN 1 -->
      <section class="column" id="column1">
        <!-- center column content (50% width) -->
        <router-view />
      </section>
    </main>
    <AppFooter />
    <div id="overlay">
      <div id="overlay-outerwrap">
        <a id="overlay-close"></a>
        <div id="overlay-wrap">
          <div id="overlay-wrapinside"></div>
        </div>
      </div>
    </div>
    <ScrollToTopButton></ScrollToTopButton>
  </div>
</template>

<script>

import LayoutMixin from '@/mixins/AppLayoutMixin.vue'

export default {
  name: 'Layout12',
  mixins: [LayoutMixin],
  data: () => ({
    layoutClass: 'layout-12'
  })
}
</script>
<style  scoped>

</style>
