import BaseService from './accountService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/employeesAuditTrails`

export default class EmployeesAuditTrails extends BaseService {
  async createEmployee (employee) {
    return this.sendRequest('POST', `${serviceURL}`, employee)
  }
  async getAllPendingEmployees () {
    return this.sendRequest('GET', `${serviceURL}/pendingapproval`)
  }
  async getAllEmployees () {
    return this.sendRequest('GET', `${serviceURL}`)
  }
  async getEmployeesByStatus (status) {
    return this.sendRequest('GET', `${serviceURL}/getbystatus/${status}`)
  }
  async approveEmployee (data) {
    return this.sendRequest('POST', `${serviceURL}/approve`, data)
  }
  async updateEmployee (employee) {
    return this.sendRequest('POST', `${serviceURL}/update-employee`, employee)
  }
  async rejectEmployee (data) {
    return this.sendRequest('POST', `${serviceURL}/reject`, data)
  }
  async returnEmployee (data) {
    return this.sendRequest('POST', `${serviceURL}/return`, data)
  }
  async approvePersonnel (data) {
    return this.sendRequest('POST', `${serviceURL}/bulkApprove`, data)
  }
}
