export const Constants = {
  BASE_API_VERSION: 'v1'
}

export const MutationTypes = {
  SET_LAYOUT: 'setLayout',
  SET_LOGIN_LAYOUT: 'seLoginLayout',
  SET_CURRENT_USER: 'setCurrentUser'
}

export const ActionTypes = {
  GET_CURRENT_USER: 'getCurrentUser'
}

export const Keys = {
  MS_GRAPH_CLIENT_ID: 'https://graph.microsoft.com',
  MS_GRAPH_URL: 'https://graph.microsoft.com/v1.0/me'
}

export const Layouts = {

}

export const ALERT_TYPE_ERROR = 'error'
export const ALERT_TYPE_SUCCESS = 'success'
export const FLOATING_FIELD_TEXTINPUT = 'text'
export const FLOATING_FIELD_TEXTAREA = 'textarea'

export const CLOSE_MODAL_EVENT = 'CLOSE_MODAL'

export const EMPLOYEE_SEARCH_STARTED_EVENT = 'EMPLOYEE_SEARCH_STARTED'
export const EMPLOYEE_SELECTED_EVENT = 'EMPLOYEE_SELECTED'

export const EDIT_USER_EVENT = 'EDIT_USER'
export const DISABLE_USER_EVENT = 'DISABLE_USER'
export const EDIT_ROLE_EVENT = 'EDIT_ROLE'
export const REMOVE_ROLE_EVENT = 'REMOVE_ROLE'

export const APP_ROUTE = 'VENDOR' | 'CHEVRON'

export const ROTATION = {
  OfficeRegular: '5/2',
  Field44: '4/4/4'
}

export const TimesheetShortCodes = {
  'Holiday': 'H',
  'Weekend': 'W',
  'Workday': '',
  'On Leave': 'L'
}
