import { salaryComponentService } from '../../../services'
import {
  FETCH_PENDING_SALARY,
  CREATE_SALARY,
  APPROVE_SALARY,
  FETCH_SALARY,
  DELETE_SALARY
} from '../../action-type'

import {
  ADD_SALARY,
  SET_PENDING_SALARY,
  SET_SALARY__APPROVAL,
  SET_SALARY,
  REMOVE_SALARY
} from '../../mutation-type'

export default {
  state: {
    pendingSalaryComponents: [],
    salaryComponents: []
  },
  mutations: {
    [ADD_SALARY] (state, payload) {
      state.pendingSalaryComponents = [...payload]
    },
    [SET_PENDING_SALARY] (state, payload) {
      state.pendingSalaryComponents = [...payload]
    },
    [SET_SALARY__APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingSalaryComponents.length; i++) {
        if (state.pendingSalaryComponents[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingSalaryComponents.splice(index, 1)
    },
    [REMOVE_SALARY] (state, id) {
      let index = null
      for (let i = 0; i < state.salaryComponents.length; i++) {
        if (state.salaryComponents[i].id === id) {
          index = i
          break
        }
      }
      state.salaryComponents.splice(index, 1)
    },
    [SET_SALARY] (state, payload) {
      state.salaryComponents = [...payload]
    }
  },
  actions: {
    async [CREATE_SALARY] ({ commit }, salary) {
      return new Promise((resolve, reject) => {
        salaryComponentService.createSalaryComponent(salary).then((result) => {
          // commit(ADD_SALARY, salary)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    async [FETCH_PENDING_SALARY] ({ commit }) {
      return new Promise((resolve, reject) => {
        salaryComponentService.getAllPendingSalaryComponent().then(result => {
          console.log(result.data.items)
          commit(SET_PENDING_SALARY, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_SALARY] ({ commit }, salary) {
      return new Promise((resolve, reject) => {
        salaryComponentService.approveSalaryComponent(salary).then(result => {
          console.log(result)
          commit(SET_SALARY__APPROVAL, salary)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_SALARY] ({ commit }) {
      return new Promise((resolve, reject) => {
        salaryComponentService.getAllSalaryComponent().then(result => {
          console.log(result)
          commit(SET_SALARY, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [DELETE_SALARY] ({ commit }, id) {
      return new Promise((resolve, reject) => {
        salaryComponentService.deleteSalaryComponent(id).then(result => {
          commit(REMOVE_SALARY, id)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }

  },
  getters: {
    pendingSalaryComponents (state) {
      return state.pendingSalaryComponents
    },
    salaryComponents (state) {
      return state.salaryComponents
    }
  }
}
