<template>
  <div id="app">
    <component v-bind:is="currentAppLayout"></component>
  </div>
</template>

<script>
import Layout12 from '@/components/layout/Layout12'
import Layout363 from '@/components/layout/Layout363'
import Layout39 from '@/components/layout/Layout39'
import Layout93 from '@/components/layout/Layout93'
import Layout66 from '@/components/layout/Layout66'

import { mapGetters } from 'vuex'

export default {
  components: {
    layout12: Layout12,
    layout363: Layout363,
    layout39: Layout39,
    layout93: Layout93,
    layout66: Layout66
  },
  computed: {
    ...mapGetters([
      'currentAppLayout'
    ])
  },
  mounted () {
    // this.$store
    //   .dispatch(FETCH_EMPLOYEES)
  }
}

</script>

<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
    font-weight: bold;
    color: #2c3e50;
  }
  .router-link-exact-active
  {
    color: #42b983;
  }
  .btn-primary {
    color: #fff;
  }

  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  button[class*="btn-"]:after {
    content: '';
    display: inline-block;
    font-size: 16px;
    padding: 0 0 0 5px;
  }
</style>
