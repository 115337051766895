<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="label">{{ title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h6>
            <b class="text-medium-orange">{{ message }}</b>
          </h6>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" v-on:click="okClicked" class="btn btn-primary">{{ okLabel ? okLabel : 'Ok' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

  .button:after, .btn:after {
    content: '';
    display: inline-block;
    font-size: 16px;
    padding: 0 0 0 5px;
  }
</style>

<script>
import Events from '@/utils/events'

export default {
  name: 'InformationDialog',
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    okLabel: {
      type: String,
      required: false
    },
    isActive: {
      type: Boolean,
      required: true
    },
    okCallback: {
      type: Function,
      required: false
    }
  },
  // props: ['id', 'title', 'message', 'okLabel', 'isActive'],
  inject: ['eventBus'],
  watch: {
    isActive () {
      // const self = this
      // if (self.isActive) {
      //   $(`#${self.id}`).modal({
      //     show: true
      //   })
      // } else {
      //   self.$nextTick(() => {
      //     $(`#${self.id}`).modal('hide')
      //   })
      // }
    }
  },
  methods: {
    okClicked () {
      if (this.okCallback) { this.okCallback() }
      this.eventBus.$emit(Events.CloseInformationDialog)
    }
  }
}
</script>
