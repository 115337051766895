'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/roles`

export default class RoleService extends BaseService {
  async getAllRoles () {
    return this.sendRequest('GET', `${serviceURL}`)
  }

  async createRole (role) {
    return this.sendRequest('POST', `${serviceURL}`, role)
  }

  async updateRole (role) {
    return this.sendRequest('PUT', `${serviceURL}`, role)
  }

  async deleteRole (id) {
    return this.sendRequest('DELETE', `${serviceURL}/${id}`)
  }
}
