export const SET_ROLES = 'setRoles'
export const ADD_ROLE = 'addRole'
export const EDIT_ROLE = 'editRole'
export const REMOVE_ROLE = 'removeRole'

export const SET_USERS = 'setUsers'
export const ADD_USER = 'addUser'
export const EDIT_USER = 'editUser'
export const SET_LAYOUT = 'setLayout'
export const SET_PENDING_USERS = 'setPendingUsers'
export const SET_USER_APPROVAL = 'setApproveUser'

export const SET_LOCATIONS = 'setLocations'
export const SET_PENDING_LOCATIONS = 'setPendingLocations'
export const ADD_LOCATION = 'addLocation'
export const EDIT_LOCATION = 'editLocation'
export const REMOVE_LOCATION = 'removeLocation'
export const SET_LOCATION__APPROVAL = 'setLocationApproval'

export const SET_EMPLOYEES = 'setEmployees'
export const ADD_EMPLOYEES = 'addEmployees'
export const SET_PENDING_EMPLOYEES = 'setPendingEmployee'
export const REMOVE_EMPLOYEE = 'removeEmployee'
export const RETURN_EMPLOYEE = 'returnEmployee'
export const SET_EMPLOYEE_APPROVAL = 'setEmployeeApproval'

export const ADD_DEPARTMENT = 'addDepartment'
export const SET_DEPARTMENT = 'setDepartment'
export const SET_PENDING_DEPARTMENT = 'setPendingDepartment'
export const EDIT_DEPARTMENT = 'editDepartment'
export const REMOVE_DEPARTMENT = 'removeDepartment'
export const SET_DEPARTMENT__APPROVAL = 'setDepartmentApproval'

export const ADD_WORKAREA = 'addWorkArea'
export const SET_WORKAREA = 'setWorkArea'
export const SET_PENDING_WORKAREA = 'setPendingWorkArea'
export const EDIT_WORKAREA = 'editWorkArea'
export const REMOVE_WORKAREA = 'removeWorkArea'
export const SET_WORKAREA__APPROVAL = 'setWorkAreaApproval'

export const ADD_CONTRACTEMPLOYER = 'addContractEmployer'
export const SET_CONTRACTEMPLOYER = 'setContractEmployer'
export const SET_PENDING_CONTRACTEMPLOYER = 'setPendingContractEmployer'
export const EDIT_CONTRACTEMPLOYER = 'editContractEmployer'
export const REMOVE_CONTRACTEMPLOYER = 'removeContractEmployer'
export const SET_CONTRACTEMPLOYER__APPROVAL = 'setContractEmployerApproval'

export const ADD_JOBTITLE = 'addJobTItle'
export const SET_JOBTITLE = 'setJobTItle'
export const SET_PENDING_JOBTITLE = 'setPendingJobTItle'
export const EDIT_JOBTITLE = 'editJobTItle'
export const REMOVE_JOBTITLE = 'removeJobTItle'
export const SET_JOBTITLE__APPROVAL = 'setJobTitleApproval'
export const SET_PENDING_UNIONJOBTITLE = 'setPendingUnionJobTitleSalaries'
export const SET_UNIONJOBTITLE_APPROVAL = 'setUnionJobTitleApproval'

export const ADD_REQUEST = 'addRequest'
export const SET_REQUEST = 'setRequest'
export const SET_PENDING_REQUEST = 'setPendingRequest'
export const EDIT_REQUEST = 'editRequest'
export const REMOVE_REQUEST = 'removeRequest'
export const SET_REQUEST_APPROVAL = 'setRequestApproval'
export const SET_BULK_REQUEST_APPROVAL = 'setRequestBulkApproval'
export const SET_BULK_LEAVE_APPROVAL = 'setLeaveBulkApproval'
export const SET_BULK_PPE_APPROVAL = 'setPpeBulkApproval'
export const SET_BULK_TRAINING_APPROVAL = 'setTrainingBulkApproval'
export const SET_PENDING_PPES = 'setPendingPpes'
export const SET_PENDING_TRAINING = 'setPendingTraining'
export const SET_PENDING_ALLOWANCES = 'setPendingAllowances'
export const SET_PENDING_LEAVE = 'setPendingLeaves'
export const SET_ALLOWANCES = 'setAllowances'
export const SET_LEAVE = 'setLeave'
export const SET_PPE = 'setPPE'
export const SET_TRAINING = 'setTraining'
export const REMOVE_FORWARDED_REQUEST = 'removeForwardedRequest'

export const ADD_SALARY = 'addSalary'
export const SET_SALARY = 'setSalary'
export const SET_PENDING_SALARY = 'setPendingSalary'
export const EDIT_SALARY = 'editSalary'
export const REMOVE_SALARY = 'removeSalary'
export const SET_SALARY__APPROVAL = 'setSalaryApproval'

export const ADD_BENEFIT = 'addBenefit'
export const SET_BENEFIT = 'setBenefit'
export const SET_PENDING_BENEFIT = 'setPendingBenefit'
export const EDIT_BENEFIT = 'editBenefit'
export const REMOVE_BENEFIT = 'removeBenefit'
export const SET_BENEFIT__APPROVAL = 'setBenefitApproval'

export const ADD_ROTATION = 'addRotation'
export const SET_ROTATION = 'setRotation'
export const SET_PENDING_ROTATION = 'setPendingRotation'
export const EDIT_ROTATION = 'editRotation'
export const REMOVE_ROTATION = 'removeRotation'
export const SET_ROTATION__APPROVAL = 'setRotationApproval'

export const SAVED_TIMESHEET = 'saveTimesheet'
export const ADD_TIMESHEET = 'addTimesheet'
export const GET_TIMESHEET = 'fetchTimesheet'
export const SET_APPROVE_TIMESHEET = 'approveTimesheet'
export const UPLOADED_TIMESHEET = 'uploadedTimesheet'

export const ADD_EXIT = 'addExit'

export const ADD_STATE = 'addState'
export const SET_STATE = 'setState'
export const SET_PENDING_STATE = 'setPendingState'
export const EDIT_STATE = 'editState'
export const REMOVE_STATE = 'removeState'
export const SET_STATE__APPROVAL = 'setStateApproval'

export const ADD_TERMINALRATE = 'addTerminalRate'
export const SET_TERMINALRATE = 'setTerminalRate'
export const SET_PENDING_TERMINALRATE = 'setPendingTerminalRate'
export const EDIT_TERMINALRATE = 'editTerminalRate'
export const REMOVE_TERMINALRATE = 'removeTerminalRate'
export const SET_TERMINALRATE_APPROVAL = 'setTerminalRateApproval'

export const SAVE_MEDICAL_RECORDS = 'saveMedicalRecords'
export const ADD_REQUISITION = 'addRequisition'
export const SET_REQUISITION = 'setRequisition'

export const ADD_HOLIDAY = 'addHoliday'
export const SET_HOLIDAY = 'setHoliday'
export const SET_PENDING_HOLIDAY = 'setPendingHoliday'
export const EDIT_HOLIDAY = 'editHoliday'
export const REMOVE_HOLIDAY = 'removeHoliday'
export const SET_HOLIDAY_APPROVAL = 'setHolidayApproval'

export const SET_CHARGE_CODE = 'setChargeCode'
export const SET_ORGANISATION = 'setOrganisation'

export const SET_DASHBOARD_REPORT = 'setDashboardReport'
