import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/workarea`

export default class WorkAreaService extends BaseService {
  async getAllWorkArea () {
    return this.sendRequest('GET', `${serviceUrl}/all`)
  }
  async createWorkArea (workarea) {
    return this.sendRequest('POST', `${serviceUrl}/new`, workarea)
  }
  async getPendingWorkArea () {
    return this.sendRequest('GET', `${serviceUrl}/pending`)
  }

  async approveWorkArea (workarea) {
    return this.sendRequest('POST', `${serviceUrl}/audit/approve`, workarea)
  }
  async updateWorkArea (workarea) {
    return this.sendRequest('POST', `${serviceUrl}/update`, workarea)
  }
  async deleteWorkArea (workarea) {
    return this.sendRequest('POST', `${serviceUrl}/delete`, workarea)
  }
}
