import { nmaIntegrationService } from '../../../services'
import {
  FETCH_CHARGE_CODE,
  FETCH_ORGANISATION
} from '../../action-type'

import {
  SET_CHARGE_CODE,
  SET_ORGANISATION
} from '../../mutation-type'

export default {
  state: {
    chargeCode: [],
    oranisation: []
  },

  mutations: {
    [SET_CHARGE_CODE] (state, payload) {
      state.chargeCode = payload
    },
    [SET_ORGANISATION] (state, payload) {
      state.oranisation = payload
    }
  },

  actions: {
    [FETCH_CHARGE_CODE] ({ commit }) {
      return new Promise((resolve, reject) => {
        nmaIntegrationService.getAllChargeCodes().then(({ data }) => {
          localStorage.setItem('chargeCode', JSON.stringify(data))
          commit(SET_CHARGE_CODE, data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    [FETCH_ORGANISATION] ({ commit }) {
      return new Promise((resolve, reject) => {
        nmaIntegrationService.getAllOrgId().then(({ data }) => {
          localStorage.setItem('organisation', JSON.stringify(data))
          commit(SET_ORGANISATION, data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }

}
