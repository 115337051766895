import { locationService } from '../../../services'
import {
  FETCH_LOCATION,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  APPROVE_LOCATION,
  FETCH_PENDING_LOCATION,
  DELETE_LOCATION
} from '../../action-type'
import {
  SET_LOCATIONS,
  ADD_LOCATION,
  EDIT_LOCATION,
  SET_LOCATION__APPROVAL,
  SET_PENDING_LOCATIONS,
  REMOVE_LOCATION
} from '../../mutation-type'

export default {
  state: {
    locations: [],
    pendingLocations: []
  },
  mutations: {
    [SET_LOCATIONS] (state, payload) {
      state.locations = [...payload]
    },
    [ADD_LOCATION] (state, payload) {
      state.locations.push(payload)
    },
    [EDIT_LOCATION] (state, location) {
      // let index = null
      // for (let i = 0; i < state.locations.length; i++) {
      //   if (state.locations[i].name === location.name) {
      //     index = i
      //     break
      //   }
      // }
      // state.locations.splice(index, 1, location)
    },
    [SET_PENDING_LOCATIONS] (state, payload) {
      state.pendingLocations = [...payload]
    },
    [SET_LOCATION__APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingLocations.length; i++) {
        if (state.pendingLocations[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingLocations.splice(index, 1)
    },
    [REMOVE_LOCATION] (state, payload) {
      let index = null
      for (let i = 0; i < state.locations.length; i++) {
        if (state.locations[i].id === payload.id) {
          index = i
          break
        }
      }
      state.locations.splice(index, 1)
    }
  },
  actions: {
    async [FETCH_LOCATION] ({ commit }) {
      return new Promise((resolve, reject) => {
        locationService
          .getLocations()
          .then(result => {
            localStorage.setItem('location', JSON.stringify(result.data.items))
            commit(SET_LOCATIONS, result.data.items)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async [CREATE_LOCATION] ({ commit }, location) {
      return new Promise((resolve, reject) => {
        console.log(location)
        commit(ADD_LOCATION, location)
        locationService
          .createLocation(location)
          .then(result => {
            commit(ADD_LOCATION, location)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async [UPDATE_LOCATION] ({ commit }, location) {
      return new Promise((resolve, reject) => {
        locationService.updateLocation(location).then((result) => {
          commit(EDIT_LOCATION, location)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [DELETE_LOCATION] ({ commit }, location) {
      return new Promise((resolve, reject) => {
        locationService.deleteLocation(location).then(result => {
          commit(REMOVE_LOCATION, location)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_LOCATION] ({ commit }, location) {
      return new Promise((resolve, reject) => {
        locationService
          .approveLocation(location)
          .then(result => {
            commit(SET_LOCATION__APPROVAL, location)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    async [FETCH_PENDING_LOCATION] ({ commit }) {
      return new Promise((resolve, reject) => {
        locationService.getPendingLocations().then(result => {
          commit(SET_PENDING_LOCATIONS, result.data.items)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    locations (state) {
      return state.locations
    },
    pendingLocations (state) {
      return state.pendingLocations
    }
  }
}
