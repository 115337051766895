import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/UnionMemberships`
const serviceURL2 = `/api/${Constants.BASE_API_VERSION}/UnionJobTitleSalaries`

export default class UnionMemberService extends BaseService {
  async getAllUnionMembers () {
    return this.sendRequest('GET', `${serviceURL}/all`)
  }
  async getAllPendingUnionMembers () {
    return this.sendRequest('GET', `${serviceURL}/PendingUnionMembers`)
  }
  async getUnionMemberships () {
    return this.sendRequest('GET', `${serviceURL}/unionMemberships`)
  }
  async addUnionMembership (data) {
    return this.sendRequest('POST', `${serviceURL}/add`, data)
  }
  async updateUnionMembership (data) {
    return this.sendRequest('POST', `${serviceURL}/update`, data)
  }
  async deleteUnionMembership (data) {
    return this.sendRequest('POST', `${serviceURL}/delete`, data)
  }
  async approveMembership (data) {
    return this.sendRequest('POST', `${serviceURL}/approve`, data)
  }
  async createUnionAllowance (data) {
    return this.sendRequest('POST', `${serviceURL2}`, data)
  }
  async getPendingUnionAllowance () {
    return this.sendRequest('GET', `${serviceURL2}/pending`)
  }
  async approveUnionAllowance (data) {
    return this.sendRequest('POST', `${serviceURL2}/approve`, data)
  }
  async getAllUnionJobTitle () {
    return this.sendRequest('GET', `${serviceURL2}/all`)
  }
  async getUnionJobtitle (id) {
    return this.sendRequest('GET', `${serviceURL2}/union/${id}`)
  }
  async updateJobtitle (data) {
    return this.sendRequest('POST', `${serviceURL2}/edit`, data)
  }
  async getAudit () {
    return this.sendRequest('GET', `${serviceURL2}/audit`)
  }
}
