import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/nmaintegrations`

export default class NmaIntegrationService extends BaseService {
  async getAllChargeCodes () {
    return this.sendRequest('GET', `${serviceUrl}/chargecodes/all`)
  }
  async getAllOrgId () {
    return this.sendRequest('GET', `${serviceUrl}/divisions/all`)
  }
  async getOrgbyId (id) {
    return this.sendRequest('GET', `${serviceUrl}/divisions/${id}`)
  }
}
