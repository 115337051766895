import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/requests`
const serviceURL = `/api/${Constants.BASE_API_VERSION}/IncomeTaxBand`

export default class RequestService extends BaseService {
  async getLeaveTypes () {
    return this.sendRequest('GET', `${serviceUrl}/leaves/types`)
  }
  // -------------------- Income Tax Band ---------------------------
  async getIncomeTaxBand () {
    return this.sendRequest('GET', `${serviceURL}`)
  }
  async getAllPendingIncomeTaxBand () {
    return this.sendRequest('GET', `${serviceURL}/PendingIncomeTaxBand`)
  }
  async addIncomeTaxBand (incomeTax) {
    return this.sendRequest('POST', `${serviceURL}/add`, incomeTax)
  }
  async approveIncomeTaxBand (incomeTax) {
    return this.sendRequest('POST', `${serviceURL}/approve`, incomeTax)
  }
  async deleteIncomeTaxBand (incomeTax) {
    return this.sendRequest('POST', `${serviceURL}/delete`, incomeTax)
  }
  async updateIncomeTaxBand (incomeTax) {
    return this.sendRequest('POST', `${serviceURL}/update`, incomeTax)
  }
  async getIncomeTaxBandById (id) {
    return this.sendRequest('GET', `${serviceURL}/${id}`)
  }
  async getPendingIncomeTaxBandById (id) {
    return this.sendRequest('GET', `${serviceURL}/PendingIncomeTaxBand/${id}`)
  }
  // ---------------------- End Income Tax Band -----------------------------

  async getRequestType () {
    return this.sendRequest('GET', `${serviceUrl}/types`)
  }
  async createRequest (request) {
    return this.sendRequest('POST', `${serviceUrl}`, request)
  }
  async getPendingRequest () {
    return this.sendRequest('GET', `${serviceUrl}/pending`)
  }
  async approveSingleRequest (request) {
    return this.sendRequest('POST', `${serviceUrl}/approval/single`, request)
  }
  async approveBulkRequest (requests) {
    return this.sendRequest('POST', `${serviceUrl}/approval/bulk`, requests)
  }
  async getPpeTypes () {
    return this.sendRequest('GET', `${serviceUrl}/ppe/types`)
  }
  async getPendingPpeTypes () {
    return this.sendRequest('GET', `${serviceUrl}/ppes/pending`)
  }
  async getApprovedPPE () {
    return this.sendRequest('GET', `${serviceUrl}/ppes/approved/all`)
  }
  async getPendingTraining () {
    return this.sendRequest('GET', `${serviceUrl}/trainings/pending`)
  }
  async getApprovedTraining () {
    return this.sendRequest('GET', `${serviceUrl}/trainings/approved`)
  }
  // async getApprovedAllowance (id, status) {
  //   return this.sendRequest('GET', `${serviceUrl}/allowances/approved/all/${id}/${status}`)
  // }
  async getApprovedAllowance (data) {
    return this.sendRequest('POST', `${serviceUrl}/allowances/allowance/reports`, data)
  }
  async getTrainingType () {
    return this.sendRequest('GET', `${serviceUrl}/training/types`)
  }
  async createTrainingRequest (training) {
    return this.sendRequest('POST', `${serviceUrl}/training/save`, training)
  }
  async createPPERequest (PPE) {
    return this.sendRequest('POST', `${serviceUrl}/ppe/save`, PPE)
  }
  async createLeaveRequest (leave) {
    return this.sendRequest('POST', `${serviceUrl}/leave/save`, leave)
  }
  async validateRequests (leaveRequest) {
    return this.sendRequest('POST', `${serviceUrl}/validate`, leaveRequest)
  }
  async createAllowanceRequest (allowance) {
    return this.sendRequest('POST', `${serviceUrl}/allowance/save`, allowance)
  }
  async getPendingAllowances (id) {
    return this.sendRequest('get', `${serviceUrl}/allowances/pending/${id}`)
  }
  async getPendingLeave () {
    return this.sendRequest('GET', `${serviceUrl}/leave/pending/group`)
  }
  async getRemainingLeaveDays (id) {
    return this.sendRequest('GET', `${serviceUrl}/leave/remaining-days/${id}`)
  }
  async getResumptionDate (data) {
    return this.sendRequest('POST', `${serviceUrl}/leave/resumption-date`, data)
  }
  async getAllowanceAll () {
    return this.sendRequest('GET', `${serviceUrl}/allowances/all`)
  }

  async getLeaveAll () {
    return this.sendRequest('GET', `${serviceUrl}/leave/grouped/all`)
  }
  async getPPEAll () {
    return this.sendRequest('GET', `${serviceUrl}/ppes/all`)
  }
  async approveBulkPpe (data) {
    return this.sendRequest('POST', `${serviceUrl}/ppe/approve/all`, data)
  }
  async getTrainingAll () {
    return this.sendRequest('GET', `${serviceUrl}/trainings/all`)
  }
  async approveBulkTraining (data) {
    return this.sendRequest('POST', `${serviceUrl}/training/approve/all`, data)
  }
  async approveBulkRequisition (data) {
    return this.sendRequest('POST', `${serviceUrl}/requisition/approve/all`, data)
  }
  async getLeave (id) {
    return this.sendRequest('GET', `${serviceUrl}/leave/${id}`)
  }
  async deleteLeave (id) {
    return this.sendRequest('DELETE', `${serviceUrl}/leave/delete/${id}`)
  }
  async deleteAllowance (data) {
    return this.sendRequest('POST', `${serviceUrl}/allowances/delete `, data)
  }
  async getRecallPending (id) {
    return this.sendRequest('GET', `${serviceUrl}/leave/employee/pending/${id}`)
  }
  async getAllPendingLeave () {
    return this.sendRequest('GET', `${serviceUrl}/leave/recall/pending`)
  }
  async approveRecall (data) {
    return this.sendRequest('POST', `${serviceUrl}/leave/recall/approve/all`, data)
  }
  async saveRecall (data) {
    return this.sendRequest('POST', `${serviceUrl}/leave/employees/reacall`, data)
  }
  approveBulkLeave (data) {
    return this.sendRequest('POST', `${serviceUrl}/leave/approve/all`, data)
  }
  async updateLeave (data) {
    return this.sendRequest('Post', `${serviceUrl}/leave/update`, data)
  }
  async updateAllowance (data) {
    return this.sendRequest('Post', `${serviceUrl}/allowance/update`, data)
  }
  async approveAllowance (data) {
    return this.sendRequest('Post', `${serviceUrl}/allowances/approve/all`, data)
  }
  async getVacationDays (data) {
    return this.sendRequest('Post', `${serviceUrl}/leave/details`, data)
  }
  async groupedAllowance (data) {
    return this.sendRequest('POST', `${serviceUrl}/allowances/grouped/all`, data)
  }
  async getAllowanceDetail (id) {
    return this.sendRequest('GET', `${serviceUrl}/allowances/${id}`)
  }
  async getAllowanceDetailByBatchId (batchId) {
    return this.sendRequest('GET', `${serviceUrl}/allowances/batch/${batchId}`)
  }
  async forwardRequest (data) {
    return this.sendRequest('POST', `${serviceUrl}/allowances/forward`, data)
  }
  async forwardTraining (data) {
    return this.sendRequest('POST', `${serviceUrl}/training/forward`, data)
  }
  async forwardPPE (data) {
    return this.sendRequest('POST', `${serviceUrl}/ppe/forward`, data)
  }
  async forwardLeave (data) {
    return this.sendRequest('POST', `${serviceUrl}/leave/forward`, data)
  }
  async forwardLeaveRecal (data) {
    return this.sendRequest('POST', `${serviceUrl}/leave/recall/forward`, data)
  }
  async ppeApprovalLogs (id) {
    return this.sendRequest('GET', `${serviceUrl}/ppe/logs/${id}`)
  }
  async trainingApprovalLogs (id) {
    return this.sendRequest('GET', `${serviceUrl}/training/logs/${id}`)
  }
  async allowanceApprovalLogs (id) {
    return this.sendRequest('GET', `${serviceUrl}/allowance/logs/${id}`)
  }
  async leaveApprovalLogs (id) {
    return this.sendRequest('GET', `${serviceUrl}/leave/logs/${id}`)
  }
}
