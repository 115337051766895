import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/benefitRateAuditTrails`

export default class BenefitService extends BaseService {
  async createBenefit (benefit) {
    return this.sendRequest('POST', `${serviceUrl}`, benefit)
  }
  async getPendingBenefits () {
    return this.sendRequest('GET', `${serviceUrl}/pending`)
  }
  async approveBenefits (benefit) {
    return this.sendRequest('POST', `${serviceUrl}/approve`, benefit)
  }
  async getBenefitSetupAll () {
    return this.sendRequest('GET', `${serviceUrl}`)
  }
}
