import { rotationService } from '../../../services'
import {
  CREATE_ROTATION,
  FETCH_ROTATION,
  FETCH_PENDING_ROTATION,
  APPROVE_ROTATION,
  UPDATE_ROTATION,
  DELETE_ROTATION
} from '../../action-type'

import {
  ADD_ROTATION,
  SET_ROTATION,
  SET_PENDING_ROTATION,
  SET_ROTATION__APPROVAL,
  EDIT_ROTATION,
  REMOVE_ROTATION
} from '../../mutation-type'

export default {
  state: {
    pendingRotation: [],
    rotation: []
  },

  mutations: {
    [ADD_ROTATION] (state, payload) {
      state.rotation.push(payload)
    },
    [SET_ROTATION] (state, payload) {
      state.rotation = [...payload]
    },
    [SET_ROTATION__APPROVAL] (state, payload) {
      console.log('payload', payload)
      let index = null
      for (let i = 0; i < state.pendingRotation.length; i++) {
        if (state.pendingRotation[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingRotation.splice(index, 1)
    },
    [SET_PENDING_ROTATION] (state, payload) {
      state.pendingRotation = [...payload]
    },
    [EDIT_ROTATION] (state, payload) {

    },
    [REMOVE_ROTATION] (state, payload) {
      state.rotation = state.rotation.filter(rot => rot.id !== payload)
    }
  },
  actions: {
    [CREATE_ROTATION] ({ commit }, rotation) {
      return new Promise((resolve, reject) => {
        rotationService
          .createRotation(rotation)
          .then(result => {
            console.log(result)
            commit(ADD_ROTATION, rotation)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [FETCH_ROTATION] ({ commit }) {
      return new Promise((resolve, reject) => {
        rotationService
          .getAllRotation()
          .then(result => {
            localStorage.setItem('rotation', JSON.stringify(result.data.items))
            commit(SET_ROTATION, result.data.items)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [FETCH_PENDING_ROTATION] ({ commit }) {
      return new Promise((resolve, reject) => {
        rotationService.getPendingRotaion().then(result => {
          console.log(result)
          commit(SET_PENDING_ROTATION, result.data.items)
          resolve()
        })
          .catch(error => {
            reject(error)
          })
      })
    },
    [APPROVE_ROTATION] ({ commit }, rotation) {
      return new Promise((resolve, reject) => {
        rotationService
          .approveRotation(rotation)
          .then(result => {
            commit(SET_ROTATION__APPROVAL, rotation)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [UPDATE_ROTATION] ({ commit }, rotation) {
      return new Promise((resolve, reject) => {
        rotationService.updateRotation(rotation).then(result => {
          commit(EDIT_ROTATION, rotation)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    [DELETE_ROTATION] ({ commit }, id) {
      return new Promise((resolve, reject) => {
        rotationService.deleteRotation(id).then(result => {
          commit(REMOVE_ROTATION, id)
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    rotations (state) {
      return state.rotation
    },
    pendingRotations (state) {
      return state.pendingRotation
    }
  }
}
