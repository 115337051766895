import axios from 'axios'

import { ServerValidationError } from '@/utils/errors'
import MsalAuthentication from '@/msal-auth'

export default class BaseService {
  async sendRequest (httpMethod, url, data, additionalHeaders, responseType) {
    let token = ''
    let headers = {}
    if (localStorage.getItem('user-token') !== null) {
      token = localStorage.getItem('user-token')
      headers = {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'useremail': localStorage.getItem('custom-user')
      }
    } else {
      token = await MsalAuthentication.acquireToken()
       headers = {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token.accessToken}`,
        'MsGraph-Auth-Token': token.accessToken,
        'useremail': token.idTokenClaims.preferred_username
      }
    }

    

    for (const headerKey in additionalHeaders) {
      headers[headerKey] = additionalHeaders[headerKey]
    }

    return new Promise((resolve, reject) => {
      axios.request({
        method: httpMethod,
        url,
        data,
        headers: headers,
        responseType: responseType || 'json'
      }).then((result) => {
        resolve(result)
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            reject(new ServerValidationError(error.response.data))
          } else {
            if (error.response.status === 500) {
              if (error.response.data) {
                reject(new Error(error.response.data.detail))
              } else {
                // console.log(JSON.stringify(error))
              }
            }
          }
        } else {
          reject(new Error(error))
        }
      })
    })
  }

  async sendFormRequest (httpMethod, url, data, additionalHeaders, responseType) {
    const token = await MsalAuthentication.acquireToken()

    let headers = {
      'content-type': 'multipart/form-data',
      'Authorization': `Bearer ${token.accessToken}`,
      'MsGraph-Auth-Token': token.accessToken,
      'useremail': token.idTokenClaims.preferred_username
    }
    console.log('UserData::', token.idTokenClaims.preferred_username)
    for (const headerKey in additionalHeaders) {
      headers[headerKey] = additionalHeaders[headerKey]
    }

    return new Promise((resolve, reject) => {
      axios.request({
        method: httpMethod,
        url,
        data,
        headers: headers,
        responseType: responseType || 'json'
      }).then((result) => {
        resolve(result)
      }).catch((error) => {
        console.log(error)
        if (error.response) {
          if (error.response.status === 400) {
            reject(new ServerValidationError(error.response.data))
          } else {
            if (error.response.status === 500) {
              if (error.response.data) {
                reject(new Error(error.response.data.detail))
              } else {
                // console.log(JSON.stringify(error))
              }
            }
          }
        } else {
          reject(new Error(error))
        }
      })
    })
  }

  async sendNoAuthRequest (httpMethod, url, data, additionalHeaders, responseType) {
    // const token = await AdalAuthentication.acquireToken(process.env.VUE_APP_AZURE_APP_ID)

    let headers = {
      'content-type': 'application/json'
    }

    for (const headerKey in additionalHeaders) {
      headers[headerKey] = additionalHeaders[headerKey]
    }

    return new Promise((resolve, reject) => {
      axios.request({
        method: httpMethod,
        url,
        data,
        headers: headers,
        responseType: responseType || 'json'
      }).then((result) => {
        resolve(result)
      }).catch((error) => {
        reject(new Error(error))
        // if (error.response) {
        //   if (error.response.status === 400) {
        //     reject(new ServerValidationError(error.response.data))
        //   } else {
        //     if (error.response.status === 500) {
        //       reject(new Error(error.response.data.detail))
        //     }
        //   }
        // } else {
        //   reject(new Error(error))
        // }
      })
    })
  }
}
