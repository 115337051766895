import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/benefits`

export default class EditService extends BaseService {
  async loadBenefits (benefit) {
    return this.sendRequest('POST', `${serviceUrl}/exit/benefits/all`, benefit)
  }
  async getExitReasons () {
    return this.sendRequest('GET', `${serviceUrl}/exitreasons/all`)
  }
  async getExitReason (id) {
    return this.sendRequest('GET', `${serviceUrl}/exitreasons/${id}`)
  }
  async createTerminalRate (data) {
    return this.sendRequest('POST', `${serviceUrl}/terminalbenefits/rate`, data)
  }
  async getPendingTerminalRates () {
    return this.sendRequest('GET', `${serviceUrl}/terminalbenefits/rate/pending/all`)
  }
  async getTerminalBenfit () {
    return this.sendRequest('GET', `${serviceUrl}/terminalbenefits/all`)
  }
  async getPendingTerminalBenefits () {
    return this.sendRequest('GET', `${serviceUrl}/terminalbenefits/pending`)
  }
  async approveTerminalBenefits (data) {
    return this.sendRequest('POST', `${serviceUrl}/terminalbenefits/approve`, data)
  }
  async getTerminalRates () {
    return this.sendRequest('GET', `${serviceUrl}/terminalbenefits/rate/all`)
  }
  async approveTerminalRates (data) {
    return this.sendRequest('POST', `${serviceUrl}/terminalbenefits/rate/approve`, data)
  }
  async createTerminalBenefit (data) {
    return this.sendRequest('POST', `${serviceUrl}/terminalbenefits/save`, data)
  }
  async submitTerminalBenefit (id) {
    return this.sendRequest('POST', `${serviceUrl}/terminalbenefits/submit/${id}`)
  }
  async endOfContract (data) {
    return this.sendRequest('GET', `${serviceUrl}/endofcontract/${data}`)
  }
}
