import { timesheetService } from '../../../services'

import {
  CREATE_TIMESHEET,
  UPLOAD_TIMESHEET,
  SAVE_TIMESHEET
} from '../../action-type'

import {
  ADD_TIMESHEET,
  UPLOADED_TIMESHEET,
  SAVED_TIMESHEET
} from '../../mutation-type'

export default {
  state: {
    timesheets: [],
    uploadedTimesheet: []
  },
  mutation: {
    [ADD_TIMESHEET] (state, payload) {
      state.timesheets.push(payload)
    },
    [UPLOADED_TIMESHEET] (state, payload) {
      state.uploadedTimesheet.push(payload)
    }
  },
  actions: {
    async [CREATE_TIMESHEET] ({ commit }, request) {
      return new Promise((resolve, reject) => {
        timesheetService.createTimesheet(request).then(result => {
          commit(ADD_TIMESHEET, request)
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [UPLOAD_TIMESHEET] ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        timesheetService.uploadTimesheet(payload).then(result => {
          commit(UPLOADED_TIMESHEET, payload.files)
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [SAVE_TIMESHEET] ({ commit }, request) {
      return new Promise((resolve, reject) => {
        timesheetService.saveTimesheet(request).then(result => {
          commit(SAVED_TIMESHEET, request)
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
