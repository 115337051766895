import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/contractemployeraudittrails`
const serviceUrl2 = `/api/${Constants.BASE_API_VERSION}/contractemployer`
export default class DepartmentService extends BaseService {
  async getAllContractEmployers () {
    return this.sendRequest('GET', `${serviceUrl2}`)
  }
  async createContractEmployer (contractEmployer) {
    return this.sendRequest('POST', `${serviceUrl}`, contractEmployer)
  }
  async getPendingContractEmployers () {
    return this.sendRequest('GET', `${serviceUrl}/pendingapproval`)
  }
  async getContractEmployer (id) {
    return this.sendRequest('GET', `${serviceUrl}/${id}`)
  }
  async approveContractEmployer (contractEmployer) {
    return this.sendRequest('POST', `${serviceUrl}/approve`, contractEmployer)
  }
  async updateContractEmployer (id, contractEmployer) {
    return this.sendRequest('POST', `${serviceUrl}/update/${id}`, contractEmployer)
  }
  async deleteContractEmployeer (id) {
    return this.sendRequest('DELETE', `${serviceUrl2}/${id}`)
  }
  async editContractEmployer (id) {
    return this.sendRequest('Get', `${serviceUrl2}/${id}`)
  }
}
