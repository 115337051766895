import { MutationTypes } from '@/utils/constants'

export default {
  state: {
    currentAppLayout: 'layout12',
    loginLayout: 'loginLayout'
  },
  mutations: {
    [MutationTypes.SET_LAYOUT] (state, layout) {
      state.currentAppLayout = layout
    },
    [MutationTypes.SET_LOGIN_LAYOUT] (state, layout) {
      state.loginLayout = layout
    }
  },
  getters: {
    currentAppLayout (state) {
      return state.currentAppLayout
    },
    loginLayout (state) {
      return state.loginLayout
    }
  }
}
