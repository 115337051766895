import { reportService } from '../../../services'
import {
  FETCH_DASHBOARD_REPORT
} from '../../action-type'

import {
  SET_DASHBOARD_REPORT
} from '../../mutation-type'

export default {
  state: {
    dashboard: []
  },
  mutations: {
    [SET_DASHBOARD_REPORT] (state, payload) {
      state.dashboard = [...payload]
    }
  },
  actions: {
    [FETCH_DASHBOARD_REPORT] ({ commit }) {
      return new Promise((resolve, reject) => {
        reportService.dashboard().then(({ data }) => {
          commit(SET_DASHBOARD_REPORT, data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    dashboard (state) {
      return state.dashboard
    }
  }
}
