<template>
  <div class="home">
   <v-container>
     <v-row>
        <v-col cols="12" class="dashboard-col">
          <div class="dashboard-header">
            <div>Dashboard</div>
          <div>
            {{msg}}
          </div>
          </div>
        </v-col>
      </v-row>
    <!-- <v-divider class="mt-4"></v-divider> -->
    <v-row v-if="loading">
      <v-col cols="4" class="col-style">
        <v-card
          class="pa-6 ma-3 card"
          color="primary"
          elevation="0"
        >
        <v-skeleton-loader

          v-bind="attrs"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="4" class="col-style">
        <v-card
          class="pa-6 ma-3 card"
          color="primary"
          elevation="0"
        >
        <v-skeleton-loader

          v-bind="attrs"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="4" class="col-style">
        <v-card
          class="pa-6 ma-3 card"
          color="primary"
          elevation="0"
        >
        <v-skeleton-loader

          v-bind="attrs"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
     <v-row v-else>
       <v-col v-for="(dash, index) in dashboard" :key="index" cols="4" class="col-style" >
         <Card :title="dash.dashboardClass" :link="dash.dashboardHref" :count="dash.dashboardSummary" :subTitle="dash.dashboardTitle"/>
       </v-col>

     </v-row>
   </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { FETCH_DASHBOARD_REPORT } from '@/store/action-type'
// @ is an alias to /src
import Card from '@/components/ui/card.vue'

export default {
  name: 'home',
  components: {
    Card
  },
  data: function () {
    return {
      msg: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userModule.currentUser
    }),
    dashboard () {
      return this.$store.getters.dashboard
    }
  },
  methods: {
    loadDashboardReport () {
      this.loading = true
      this.$store.dispatch(FETCH_DASHBOARD_REPORT).then(() => { }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    const currentDate = new Date()
    const hours = currentDate.getHours()
    const timeOfDay = hours < 12 ? 'morning' : (hours < 16 ? 'afternoon' : 'evening')
    this.msg = `good ${timeOfDay}, ${this.currentUser.fullName}`
    this.loadDashboardReport()
  }
}
</script>
<style scoped>
.col-style{
  background-color: transparent !important;
  padding: 9px 15px !important;
}
.card {
  border-radius: 9px !important;
  display: flex;
  height: 118px;
  justify-content: center;
}
.dashboard-col {
  background-color: transparent !important;
}
.dashboard-header {
  display: flex;
    justify-content: space-between;
    min-height: 70px;
    align-items: center;
    padding: 0 30px !important;
    margin:0 15px;
    background-color: #fff;
}
</style>
