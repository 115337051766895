const Roles = {
  LCE: 'LCE',
  SCM: 'SCM',
  LCM: 'LCM',
  HRCLU: 'HRCLU',
  FINANCE: 'Finance',
  SUPERVISOR: 'SuperVisor',
  SUPERINTENDENT: 'Superintendent',
  ADMINISTRATOR: 'Admin'
}

export default Roles
