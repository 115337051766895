import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/Messages`

export default class MessageServices extends BaseService {
  async sendMessage () {
    return this.sendRequest('GET', `${serviceUrl}/send/pending/mail`)
  }
}
