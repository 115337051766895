import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/medicalscreeningsubcategory`

export default class MedicalScreeningSubCategoryService extends BaseService {
  async getMedicalScreenSubCategory () {
    return this.sendRequest('GET', `${serviceUrl}`)
  }

  async saveMedicalScreenSubCategories (request) {
    return this.sendRequest('POST', `${serviceUrl}`, request)
  }

  async deleteMedicalScreenSubCategory (id) {
    return this.sendRequest('DELETE', `${serviceUrl}/${id}`)
  }
}
