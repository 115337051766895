import { employeeRequisition } from '../../../services'
import {
  APPROVE_REQUISITION,
  CREATE_REQUISITION,
  FETCH_REQUISITION
} from '../../action-type'

import {
  ADD_REQUISITION,
  SET_REQUISITION,
  SET_REQUISITION_APPROVAL
} from '../../mutation-type'

export default {
  state: {
    requisition: [],
    requisitions: []
  },
  mutations: {
    [ADD_REQUISITION] (state, payload) {
      state.requisition = [...payload]
    },
    [SET_REQUISITION] (state, payload) {
      state.requisitions = payload
    },
    [SET_REQUISITION_APPROVAL] (state, payload) {
      state.requisitionApproval = payload
    }
  },
  actions: {
    [CREATE_REQUISITION] ({ commit }, data) {
      return new Promise((resolve, reject) => {
        employeeRequisition.createRequisition(data).then(result => {
          commit(ADD_REQUISITION, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    async [FETCH_REQUISITION] ({ commit }) {
      return new Promise((resolve, reject) => {
        employeeRequisition.getRequisitionAll().then(result => {
          commit(SET_REQUISITION, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    async [APPROVE_REQUISITION] ({ commit }, requisit) {
      console.log('Im approve page')
      return new Promise((resolve, reject) => {
        console.log('Im here')
        employeeRequisition.approveRequisition(requisit).then(() => {
          commit(SET_REQUISITION_APPROVAL, requisit)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }

  },

  getters: {
    requisitions: state => state.requisitions
  }
}
