'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/EmployeesMoveAuditTrails`

export default class EmployeesMoveAuditTrailsService extends BaseService {
  async createPersonnelMove (personnel) {
    return this.sendRequest('POST', `${serviceURL}`, personnel)
  }
  async getAllPersonnelMove () {
    return this.sendRequest('GET', `${serviceURL}`)
  }
  async getPersonnelById (id) {
    return this.sendRequest('GET', `${serviceURL}/${id}`)
  }
  async getPendingPersonnelMove () {
    return this.sendRequest('GET', `${serviceURL}/pendingapproval`)
  }
  async getLcePendingPersonnelMove () {
    return this.sendRequest('GET', `${serviceURL}/lce-pending-approval`)
  }
  async approveEmployeePersonnelMove (data) {
    return this.sendRequest('POST', `${serviceURL}/Approve`, data)
  }
  async approveLceEmployeePersonnelMove (data) {
    return this.sendRequest('POST', `${serviceURL}/lce-approve`, data)
  }
  async getReceivingGM (id) {
    return this.sendRequest('GET', `${serviceURL}/get-approvers/${id}`)
  }
}
