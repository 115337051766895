import { benefitService } from '../../../services'

import { CREATE_TERMINALRATE, FETCH_PENDING_TERMINALRATE, FETCH_TERMINALRATE, APPROVE_TERMINALRATE, CREATE_TERMINALBENEFIT } from '../../action-type'
import { SET_PENDING_TERMINALRATE, SET_TERMINALRATE_APPROVAL, SET_TERMINALRATE } from '../../mutation-type'

export default {
  state: {
    exitBenefit: [],
    terminalRates: [],
    pendingTerminalRates: []
  },

  mutations: {
    [SET_PENDING_TERMINALRATE] (state, payload) {
      state.pendingTerminalRates = payload
    },
    [SET_TERMINALRATE] (state, payload) {
      state.terminalRates = payload
    },
    [SET_TERMINALRATE_APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingTerminalRates.length; i++) {
        if (state.pendingTerminalRates[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingTerminalRates.splice(index, 1)
    }
  },
  actions: {
    async [CREATE_TERMINALRATE] ({ commit }, data) {
      return new Promise((resolve, reject) => {
        benefitService.createTerminalRate(data).then(result => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_PENDING_TERMINALRATE] ({ commit }) {
      return new Promise((resolve, reject) => {
        benefitService.getPendingTerminalRates().then(result => {
          commit(SET_PENDING_TERMINALRATE, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [FETCH_TERMINALRATE] ({ commit }) {
      return new Promise((resolve, reject) => {
        benefitService.getTerminalRates().then(result => {
          commit(SET_TERMINALRATE, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_TERMINALRATE] ({ commit }, data) {
      return new Promise((resolve, reject) => {
        benefitService.approveTerminalRates(data).then(() => {
          commit(SET_TERMINALRATE_APPROVAL, data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [CREATE_TERMINALBENEFIT] ({ commit }, data) {
      return new Promise((resolve, reject) => {
        benefitService.createTerminalBenefit(data).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    pendingTerminalRate: state => state.pendingTerminalRates,
    terminalRate: state => state.terminalRates
  }
}
