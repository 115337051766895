import { workAreaService } from '../../../services'
import {
  CREATE_WORKAREA,
  FETCH_WORKAREA,
  APPROVE_WORKAREA,
  FETCH_PENDING_WORKAREA,
  UPDATE_WORKAREA,
  DELETE_WORKAREA
} from '../../action-type'
import {
  ADD_WORKAREA,
  SET_WORKAREA,
  SET_WORKAREA__APPROVAL,
  SET_PENDING_WORKAREA,

  REMOVE_WORKAREA
} from '../../mutation-type'

export default {
  state: {
    workAreas: [],
    pendingWorkAreas: []
  },
  mutations: {
    [ADD_WORKAREA] (state, payload) {
      state.workAreas.push(payload)
    },
    [SET_WORKAREA] (state, payload) {
      state.workAreas = [...payload]
    },
    [SET_PENDING_WORKAREA] (state, payload) {
      state.pendingWorkAreas = [...payload]
    },
    [SET_WORKAREA__APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingWorkAreas.length; i++) {
        if (state.pendingWorkAreas[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingWorkAreas.splice(index, 1)
    },
    [REMOVE_WORKAREA] (state, payload) {
      let index = null
      for (let i = 0; i < state.workAreas.length; i++) {
        if (state.workAreas[i].id === payload.id) {
          index = i
          break
        }
      }
      state.workAreas.splice(index, 1)
    }
  },
  actions: {
    [CREATE_WORKAREA] ({ commit }, workArea) {
      return new Promise((resolve, reject) => {
        workAreaService
          .createWorkArea(workArea)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [FETCH_WORKAREA] ({ commit }) {
      return new Promise((resolve, reject) => {
        workAreaService
          .getAllWorkArea()
          .then(result => {
            console.log(result)
            localStorage.setItem('workArea', JSON.stringify(result.data.items))
            commit(SET_WORKAREA, result.data.items)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    [APPROVE_WORKAREA] ({ commit }, workArea) {
      return new Promise((resolve, reject) => {
        workAreaService
          .approveWorkArea(workArea)
          .then(result => {
            commit(SET_WORKAREA__APPROVAL, workArea)
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    async [FETCH_PENDING_WORKAREA] ({ commit }) {
      return new Promise((resolve, reject) => {
        workAreaService.getPendingWorkArea().then(result => {
          commit(SET_PENDING_WORKAREA, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [UPDATE_WORKAREA] ({ commit }, workarea) {
      return new Promise((resolve, reject) => {
        workAreaService.updateWorkArea(workarea).then(result => {

        }).catch(error => {
          reject(error)
        })
      })
    },
    async [DELETE_WORKAREA] ({ commit }, workarea) {
      return new Promise((resolve, reject) => {
        workAreaService.deleteWorkArea(workarea).then(result => {
          commit(REMOVE_WORKAREA, workarea)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    workAreas (state) {
      return state.workAreas
    },
    pendingWorkAreas (state) {
      return state.pendingWorkAreas
    }
  }
}
