'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/employeerequisition`
const serviceUrl = `/api/${Constants.BASE_API_VERSION}/requests`

export default class EmployeeRequisition extends BaseService {
  async createRequisition (data) {
    return this.sendRequest('POST', `${serviceURL}`, data)
  }
  async getRequisitionAll () {
    return this.sendRequest('GET', `${serviceURL}`)
  }
  async getRequisitionById (id) {
    return this.sendRequest('GET', `${serviceURL}/${id}`)
  }
  async approveRequisition (data) {
    return this.sendRequest('POST', `${serviceUrl}/requisition/approve/all`, data)
  }
}
