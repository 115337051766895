<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="label">{{ title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h6>
            {{ label }} <br /><br />
            <b class="text-medium-orange">{{ message }}</b>
          </h6>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" v-on:click="noClicked">{{ noLabel ? noLabel : 'No' }}</button>
          <button type="button" v-on:click="yesClicked" class="btn btn-primary">{{ yesLabel ? yesLabel : 'Yes' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .button:after, .btn:after {
    content: '';
    display: inline-block;
    font-size: 16px;
    padding: 0 0 0 5px;
  }

  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
</style>

<script>
import Events from '@/utils/events'

export default {
  name: 'ConfirmationDialog',
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    message: {
      type: String,
      required: true
    },
    yesLabel: {
      type: String,
      required: false
    },
    noLabel: {
      type: String,
      required: false
    },
    isActive: {
      type: Boolean,
      required: true
    },
    yesCallback: {
      type: Function,
      required: false
    },
    noCallback: {
      type: Function,
      required: false
    }
  },
  inject: ['eventBus'],
  watch: {
    isActive () {
      // const self = this
      // if (self.isActive) {
      //   $(`#${self.id}`).modal({
      //     backdrop: 'static', // remove ability to close modal with click
      //     keyboard: false, // remove option to close with keyboard
      //     show: true
      //   })
      // } else {
      //   self.$nextTick(() => {
      //     $(`#${self.id}`).modal('hide')
      //   })
      // }
    }
  },
  methods: {
    yesClicked () {
      if (this.yesCallback) { this.yesCallback() }
      this.eventBus.$emit(Events.CloseConfirmationDialog)
    },
    noClicked () {
      if (this.noCallback) { this.noCallback() }
      this.eventBus.$emit(Events.CloseConfirmationDialog)
    }
  }
}
</script>
