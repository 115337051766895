<template>
  <v-text-field
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    outlined
    required
    :type="type"
    :rules=" validate ? rules[type] : []"
    dense
    :disabled = disable
    :hint="hint"
    persistent-hint
    :min="min"
    :max="max"
    :label="label">
  </v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    disable: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Boolean,
      default: true
    },
    hint: {
      type: String,
      default: ''
    },
    min: [Number, String],
    max: [Number, String]

  },
  data: () => ({
    rules: {
      email: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      text: [val => (val || '').length > 0 || 'This field is required'],
      date: [val => (val || '').length > 0 || 'This field is required'],
      number: [val => (val || '').length > 0 || 'This field is required']
    }
  }),
  methods: {
    valiadation () {
      if (this.type === 'text') {
        return this.rules.text
      }
    }
  }
}
</script>
<style scoped>
</style>
