import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceUrl = `/api/${Constants.BASE_API_VERSION}/salarycomponents`
const serviceURL = `/api/${Constants.BASE_API_VERSION}/module`

export default class SalaryComponents extends BaseService {
  async createSalaryComponent (salary) {
    console.log('salary', salary)
    return this.sendRequest('POST', `${serviceUrl}`, salary)
  }
  async getAllPendingSalaryComponent () {
    return this.sendRequest('GET', `${serviceUrl}/pending/all`)
  }

  async getAllSalaryAllowance () {
    return this.sendRequest('GET', `${serviceUrl}/allowances/all`)
  }
  async getRequestSalaryAllowance () {
    return this.sendRequest('GET', `${serviceUrl}/request/allowances/all`)
  }
  // -------------Begin Module Approvers--------------------------------
  async getModuleApprovers (moduleId, salaryComponentId) {
    //   Get Module and approvers
    return this.sendRequest('GET', `${serviceURL}/approvers/${moduleId}/${salaryComponentId}`)
  }
  async createApprovers (approvers) {
    console.log('data', approvers)
    return this.sendRequest('POST', `${serviceURL}/approver/save`, approvers)
  }
  async getPendingModule () {
    return this.sendRequest('GET', `${serviceURL}/pending`)
  }
  async approveModule (payload) {
    return this.sendRequest('POST', `${serviceURL}/approve`, payload)
  }
  // -----------------------End-------------------------

  async getAllUnionAllowance () {
    return this.sendRequest('GET', `${serviceUrl}/rates/allowances/all`)
  }
  async getAllSalaryBenefits () {
    return this.sendRequest('GET', `${serviceUrl}/benefits/all`)
  }

  async approveSalaryComponent (salary) {
    return this.sendRequest('POST', `${serviceUrl}/approve`, salary)
  }

  async getAllSalaryComponent () {
    return this.sendRequest('GET', `${serviceUrl}`)
  }

  async getSalaryComponent (id) {
    return this.sendRequest('GET', `${serviceUrl}/${id}`)
  }

  async getLabel (id) {
    return this.sendRequest('GET', `${serviceUrl}/label/${id}`)
  }

  async deleteSalaryComponent (id) {
    return this.sendRequest('POST', `${serviceUrl}/delete/${id}`)
  }
  async getVacationAllowance () {
    return this.sendRequest('GET', `${serviceUrl}/vacation/allowances/all`)
  }
  async getDeductions () {
    return this.sendRequest('GET', `${serviceUrl}/deductions/all`)
  }
}
