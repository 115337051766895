import { userService } from '../../../services'
import { ActionTypes, MutationTypes } from '@/utils/constants'
import {
  FETCH_USERS,
  CREATE_USER,
  UPDATE_USER,
  FETCH_PENDING_USERS,
  APPROVE_USER
} from '../../action-type'
import {
  SET_USERS,
  ADD_USER,
  EDIT_USER,
  SET_PENDING_USERS,
  SET_USER_APPROVAL
} from '../../mutation-type'

export default {
  state: {
    currentUser: {
      id: null,
      firstName: null,
      lastName: null,
      fullName: null,
      name: null,
      roleId: null,
      roleName: null,
      roles: []
    },
    users: [],
    pendingUsers: []
  },
  mutations: {
    [MutationTypes.SET_CURRENT_USER] (state, user) {
      state.currentUser.id = user.id
      state.currentUser.firstName = user.name
      state.currentUser.lastName = user.name
      state.currentUser.fullName = user.displayName
      state.currentUser.name = user.name
      state.currentUser.roleId = user.roleId
      state.currentUser.roleName = user.roleName
      state.currentUser.roles = user.roles

      // state.currentUser.id = user.id
      // state.currentUser.firstName = user.employeeInfo.firstName
      // state.currentUser.lastName = user.employeeInfo.lastName
      // state.currentUser.fullName = user.employeeInfo.name
    },
    [SET_USERS] (state, users) {
      state.users = [...users]
    },
    [SET_PENDING_USERS] (state, payload) {
      state.pendingUsers = payload
    },
    [ADD_USER] (state, user) {
      state.users.push(user)
    },
    [EDIT_USER] (state, user) {
      let index = null
      for (let i = 0; i < state.users.length; i++) {
        if (state.users[i].id === user.id) {
          index = i
          break
        }
      }
      state.users.splice(index, 1, user)
    },
    [SET_USER_APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingUsers.length; i++) {
        if (state.pendingUsers[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingUsers.splice(index, 1)
    }
  },
  actions: {
    [ActionTypes.GET_CURRENT_USER] ({ commit }) {
      return new Promise((resolve, reject) => {
        userService.getCurrentUser().then((result) => {
          if (result.status === 200) {
            commit(MutationTypes.SET_CURRENT_USER, result.data)
          }
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [FETCH_USERS] ({ commit }) {
      return new Promise((resolve, reject) => {
        userService.getAllUsers().then((result) => {
          commit(SET_USERS, result.data.items)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [CREATE_USER] ({ commit }, user) {
      return new Promise((resolve, reject) => {
        userService.createUser(user).then((result) => {
          commit(ADD_USER, user)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [UPDATE_USER] ({ commit }, user) {
      return new Promise((resolve, reject) => {
        userService.updateRole(user).then((result) => {
          commit(EDIT_USER, user)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [FETCH_PENDING_USERS] ({ commit }) {
      return new Promise((resolve, reject) => {
        userService.getPendingUsers().then(result => {
          commit(SET_PENDING_USERS, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_USER] ({ commit }, user) {
      return new Promise((resolve, reject) => {
        userService.approveUser(user).then(result => {
          commit(SET_USER_APPROVAL, result.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    currentUser: state => state.currentUser,
    users: state => state.currentUser,
    user: state => state.users,
    pendingUser: state => state.pendingUsers
  }
}
